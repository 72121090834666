import React from 'react'

import {
    Button,
    Backdrop,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core'

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { red } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '550px',
    },
    gridInputContainer:{
        padding: theme.spacing(3),
    },
    gridButtonContainer:{
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    inputField: {
        maxWidth: '160px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 100,
        color: '#fff',
    }
}));


const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(Button);

export default function ConfirmDialog({ context, onAccept, onCancel, hasCancel=true }) {

    const classes = useStyles()

    return (
        <Backdrop className={classes.backdrop} open={context.open}>
            <Paper className={classes.root}>
                <Grid container className={classes.gridInputContainer}>
                    <Grid item>
                        <Typography>{context.text}</Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className={classes.gridButtonContainer}
                >
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" onClick={() => { onAccept() }}>OK</Button>
                    </Grid>
                    <Grid item xs={6}>
                        {hasCancel ? <RedButton variant="contained" onClick={() => { onCancel() }}>Afbryd</RedButton> : <></>}
                    </Grid>
                </Grid>
            </Paper>
        </Backdrop>
    )
}