import React from "react"

import {
    MenuItem,
    MenuList,
    ListItemIcon,
    Paper,
    CircularProgress,
    Tooltip
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { withStyles } from "@material-ui/core/styles";

const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
    }
})(Tooltip);

export default function Menu({items, onClick, onDblClick}) {

    if(items){ 
        function buttonClick(id){
            onClick(id)
        }
        function buttonDblClick(id){
            onDblClick(id)
        }

        var menuItemComponents = items.map(menuObj => 
            <MenuItem 
                id={menuObj.observationFacilityId}
                key={menuObj.observationFacilityId}
                onClick={(e)=>buttonClick(menuObj.observationFacilityId)}
                onDoubleClick={(e) => buttonDblClick(menuObj.observationFacilityId)}
                style={{fontColor: '#707070'}}
            >
                {StationStatus(menuObj.icon)} {menuObj.name}
            </MenuItem>
        )

        return(
            <Paper style={{height: '100%'}}>
                <MenuList style={{ maxHeight: '70vh', maxWidth: '398px', overflow: 'auto' }}>
                    {menuItemComponents}
                </MenuList>
            </Paper>
        );

    } else { return <CircularProgress /> }
}


const StationStatus = (status) => {

    if(status == "loading"){
        return(
            <Tooltip title='Loading.' interactive>
                <ListItemIcon>
                    <CircularProgress size={18} disableShrink style={{marginRight: '13px'}} />
                </ListItemIcon>
            </Tooltip>
        )
    }

    if(status == "ok"){
        return(
            <CustomTooltip title='Ok.' interactive>
                <ListItemIcon>
                    <CheckCircleIcon size={14} style={{fill:'green', marginRight: '7px'}} />
                </ListItemIcon>
            </CustomTooltip>
        )
    }

    if(status == "warning"){
        return(
            <CustomTooltip title='Mangler vandtype.' interactive size="12px">
                <ListItemIcon>
                    <WarningIcon size={14} style={{fill:'orange', marginRight: '7px'}} />
                </ListItemIcon>
            </CustomTooltip>
        )
    }

    if(status == "error"){
        return(
            <CustomTooltip title='E.Coli eller Intestinal Enterococcus over niveau.' interactive>
                <ListItemIcon>
                    <ErrorIcon size={14} style={{fill:'red', marginRight: '7px'}} />
                </ListItemIcon>
            </CustomTooltip>
        )
    }

    if (status == "nodata") {
        return (
            <CustomTooltip title='Ingen data fundet.' interactive>
                <ListItemIcon>
                    <RemoveCircleIcon size={14} style={{ fill: 'gray', marginRight: '7px' }} />
                </ListItemIcon>
            </CustomTooltip>
        )
    }

    return(<div></div>)
}