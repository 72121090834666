import React, { useState, useCallback, useEffect, memo } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import MapToolTip from './Tooltip';
import CustomMarker from './CustomMarkers';
const containerStyle = {
    width: '100%',
    height: '70vh'
};


function Map({ options, stations, callback }) {
    const [map, setMap] = useState(null)
    const [markers, setMarkers] = useState(null)
    const [toolTip, setToolTip] = useState(null)

    const { isLoaded, loadError } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN
    })
    const onLoad = useCallback(function callback(map) {
        buildMarkers()
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    function mapToolTip(id) {
        var obj = stations.find(x => x.observationFacilityId === id);
        setToolTip(
            <MapToolTip
                anchor={{ lat: obj.position.latitude + 0.001, lng: obj.position.longitude }}
                onClose={() => setToolTip(undefined)}
                props={obj}
            />
        )
    }

    function buildMarkers() {
        if (!stations)
            return

        var markers = stations.map((obj, i) => {
            return (
                <CustomMarker
                    key={`map-marker-${i}`}
                    onClickEffect={() => callback(obj.observationFacilityId)}
                    onMouseOverEffect={() => mapToolTip(obj.observationFacilityId)}
                    icon={obj.icon}
                    position={{ lat: obj.position.latitude, lng: obj.position.longitude }}
                />
            )
        })
        setMarkers(markers)
    }


    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={options}
        >
            {markers}
            {toolTip}
        </GoogleMap>
    ) : <></>
}



export default memo(Map)