import React, { useState, useEffect } from 'react'
import { InfoWindow } from '@react-google-maps/api';


export default function MapToolTip({ anchor, props, onClose }) {
    var innerContent = props.classifications.map((element, i) => {
        return (
            <tr key={`tr-key-${i}`}>
                <td>{element.year}</td>
                <td>
                    <div><svg height="20" width="20"><circle cx="10" cy="10" r="8" stroke="black" strokeWidth="1" fill={element.klassifikation} /></svg></div>
                </td>
                <td>{element.p95ecoli}</td>
                <td>{element.p95entero}</td>
                <td>{element.p90ecoli}</td>
                <td>{element.p90entero}</td>
                <td>{element.count}</td>
            </tr>
        )
    })

    return (
        <InfoWindow position={anchor} onCloseClick={()=>onClose()}>
            <div>
                <div style={{align:'center'}}>Klassifikation for {props.name}</div>
                <table cellSpacing="15" style={{fontSize: "12px"}}>
                    <thead>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>E.coli P95</td>
                        <td>Entero P95</td>
                        <td>E.coli P90</td>
                        <td>Entero P90</td>
                        <td>Antal</td>
                    </tr>
                    </thead>
                    <tbody>
                    {innerContent}
                    </tbody>
                </table>
            </div>
        </InfoWindow>
    )
}