import React from 'react';

import {
    Grid,
    Paper,
    Typography,
    CircularProgress,
} from '@material-ui/core'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';

export default function LoadingOverlay({ text, isError = false }) {
    const theme = createMuiTheme({
        palette: {
            primary: blue,
            secondary: green
        },
    })

    if (isError) {
        return (
            <ThemeProvider theme={theme}>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}>
                    <Grid key={2} item align='center'>
                        <Paper style={{ width: '17vw', height: '17vh' }}>
                            <br />
                            <ErrorIcon style={{ fontSize: '48px', fill: 'red' }} />
                            <br />
                            <Typography variant="h5" align='center'>Fejl</Typography>
                            <br />
                            <Typography variant="caption" align='center'>{text}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    } else {
        return (
            <ThemeProvider theme={theme}>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}>
                    <Grid key={2} item align='center'>
                        <Paper style={{ width: '17vw', height: '17vh' }}>
                            <br />
                            <CircularProgress color="primary" />
                            <br />
                            <Typography variant="h5" align='center'>Loading...</Typography>
                            <br />
                            <Typography variant="caption" align='center'>{text}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    }
}