import React, { useReducer, useEffect } from 'react'

import {
    Container,
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    CircularProgress,
    Snackbar
} from '@material-ui/core'

import MuiAlert from '@material-ui/lab/Alert';


import {
    ThemeProvider,
    makeStyles,
    createMuiTheme
} from '@material-ui/core/styles';

import { green, blue, red } from '@material-ui/core/colors';

import { reducer, ACTIONS } from './partials/reducer.js';

import {
    Menu,
    PollutionContext,
    CustomSelect,
    StepForm,
    ConfirmDialogInput,
    ConfirmDialog
} from '../../components'

import {
    dateToPrint
} from '../../utilities/DateUtil'

import {
    extractPULSDataToJSON
} from '../../utilities/services'

import restClient from '../../utilities/api';

const _ = require('lodash');


var pulsClient = new restClient(process.env.REACT_APP_DATA_URL);


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    GridElement: {
        backgroundColor: '#999999',
    },
    GridContainer: {
        paddingTop: '15px'
    },
});

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: red,
    },
    status: {
        danger: 'orange'
    }
})

export default function Pollutions({ user, stations }) {

    const [state, dispatch] = useReducer(reducer, {
        ready: false,
        disclaimer:{
            open: true,
            text: `Advarsel.
            Vær opmærksom på at den nuværende udgave af PULS ikke tillader
            at slette korttidsforureninger igen.
            Vær derfor sikker på at oprettelsen er korrekt!
            `
        },
        dataLoading: false,
        error: {
            text: '',
            severity: '',
            open: false
        },
        createForm: {
            show: false,
            data: [],
        },
        confirmRemoval: {
            text: '',
            reason: '',
            id: undefined,
            open: false
        },
        stationList: stations,
        user: user,
        pollutionList: [],
        selectOptions: [0, 1, 2, 3].map(x => { return new Date().getFullYear() - x }),
        selected: {
            station: stations[0],
            year: new Date().getFullYear(),
            pollutions: []
        },
    })

    const classes = useStyles();

    const handleMenuClick = (id) => {
        var foundStation = state.stationList.find(x => x.observationFacilityId === id)
        dispatch({ type: ACTIONS.SET_SELECTED_STATION, payload: foundStation })
    }

    const handleCreateNew = async () => {
        var id = state.selected.station?.observationFacilityId
        if (id != undefined) {
            dispatch({ type: ACTIONS.TOGGLE_CREATE_FORM })
            var data = await callForData(id, state.selected.year)
            dispatch({ type: ACTIONS.SET_FORM_DATA, payload: extractPULSDataToJSON(data) })
        }
    }

    const handleDeleteRequest = async (id) => {
        dispatch({ type: ACTIONS.SET_REMOVAL_ID, payload: id })
        dispatch({ type: ACTIONS.SET_CONFIRM_TEXT, payload: 'Er du sikker på du vil slette?' })
        dispatch({ type: ACTIONS.TOGGLE_CONFIRM_BOX })
    }

    const confirmRemoval = async () => {
        if (state.confirmRemoval.reason === '') {
            dispatch({ type: ACTIONS.SHOW_ERROR, payload: { text: `Der skal angives en begrundelse.`, severity: 'warning' } })
            return;
        }

        await pulsClient.RemoveShortTermPollution(state.confirmRemoval.id,state.confirmRemoval.reason)
        dispatch({ type: ACTIONS.TOGGLE_CONFIRM_BOX })

        dispatch({type:ACTIONS.SET_DATA_LOADING})
        setTimeout(async ()=>{
            await refreshPollutionList()
        }, 3000)
    }

    const parseCreate = async (obj) => {
        dispatch({ type: ACTIONS.TOGGLE_CREATE_FORM })
        const response = await pulsClient.PostShortTermPollution(obj)

        dispatch({type:ACTIONS.SET_DATA_LOADING})
        setTimeout(async ()=>{
            await refreshPollutionList()
        }, 3000)
    }

    const refreshPollutionList = async () => {
        
        var response = await pulsClient.GetShortTermPollutions(state.selected.station?.observationFacilityId, state.selectOptions)
        response.map(list => {
            list.data = list.data.map(obj => {
                obj.sample.samplingTime = dateToPrint(obj.sample.samplingTime)
                obj.confirmationSample.samplingTime = dateToPrint(obj.confirmationSample.samplingTime)
                obj.replacementSample.samplingTime = dateToPrint(obj.replacementSample.samplingTime)
                return obj
            })
            return list
        })
        dispatch({ type: ACTIONS.SET_POLLUTION_LIST, payload: response })
        dispatch({type:ACTIONS.SET_DATA_LOADING})
    }

    useEffect(async () => {
        if (state.selected.station != undefined) {
            dispatch({type:ACTIONS.SET_DATA_LOADING})
            await refreshPollutionList()
        }
    }, [state.selected.station])

    useEffect(() => {
        var pollutionData = state.pollutionList.filter((x) => { return x.id === state.selected.year })[0]
        dispatch({ type: ACTIONS.SET_SELECTED_POLLUTION, payload: pollutionData?.data })
    }, [state.selected.year, state.pollutionList])

    useEffect(() => {
        if (user != null & stations != null)
            dispatch({ type: ACTIONS.SET_READY })
    }, [user, stations])

    if (!state.ready) {
        return (
            <ThemeProvider theme={theme}>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid key={2} item align='center'>
                        <Paper style={{ width: '17vw', height: '17vh' }}>
                            <br />
                            <CircularProgress />
                            <br />
                            <Typography variant="h5" align='center'>Loading...</Typography>
                            <br />
                            <Typography variant="caption" align='center'>{state.loadingSubMessage}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Container className={classes.root} maxWidth='lg'>
                <Grid className={classes.GridContainer}
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={2}
                >
                    <Grid className={classes.GridElement}
                        item
                        xs={4}
                    >
                        <Menu
                            items={state.stationList}
                            onClick={(id) => handleMenuClick(id)}
                            onDblClick={() => { }}
                        />
                    </Grid>
                    <Grid className={classes.GridElement}
                        item
                        xs={8}
                    >
                        <Paper style={{ width: '100%' }}>
                            <Grid container direction="row" justify="space-between" style={{ padding: '10px' }}>
                                <Grid item>
                                    <Typography variant="h6">{state.selected.station?.name}</Typography>
                                    <CustomSelect
                                        selected={state.selected.year}
                                        options={state.selectOptions}
                                        callback={(value) => dispatch({ type: ACTIONS.SET_SELECTED_YEAR, payload: value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        // disabled={new Date().getFullYear() != state.selected.year}
                                        onClick={() => { handleCreateNew() }}
                                    > Opret</Button>
                                </Grid>

                            </Grid>
                        </Paper>
                        <Paper style={{ width: '100%', marginTop: '5px', minHeight:'48px'}}>
                            {
                                state.dataLoading ? 
                                <div style={{textAlign:'center'}}>
                                    <CircularProgress />
                                </div>
                                :
                                <PollutionContext
                                    pollutions={state.selected.pollutions}
                                    callback={(e) => { handleDeleteRequest(e) }}
                                />
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <StepForm
                data={state.createForm.data}
                open={state.createForm.show}
                year={state.selected.year}
                onAccept={(obj) => { parseCreate(obj) }}
                onCancel={() => { dispatch({ type: ACTIONS.TOGGLE_CREATE_FORM }) }}
            />
            <ConfirmDialogInput
                context={state.confirmRemoval}
                onChange={(text) => { dispatch({ type: ACTIONS.SET_REMOVAL_REASON, payload: text }) }}
                onAccept={() => { confirmRemoval() }}
                onCancel={() => { dispatch({ type: ACTIONS.TOGGLE_CONFIRM_BOX }) }}
            />
            <ConfirmDialog
                context={state.disclaimer}
                hasCancel={false}
                onAccept={()=>{ dispatch({type: ACTIONS.SET_STATE, payload:{name:'disclaimer',state:{text:state.disclaimer.text, open: false}}})}}
                onCancel={()=>{ dispatch({type: ACTIONS.SET_STATE, payload:{name:'disclaimer',state:{text:state.disclaimer.text, open: false}}})}}
            />
            <Snackbar open={state.error.open} autoHideDuration={6000} onClose={() => dispatch({ type: ACTIONS.HIDE_ERROR })}>
                <Alert severity={state.error.severity}>
                    {state.error.text}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}



const callForData = async (id,year) => {
    var response = await pulsClient.GetExamination(id, `${year}-01-01`, `${year + 1}-01-01`);
    if (response.status !== 200)
        return undefined

    return response.data
}