
export default function LoginProcess(baseURL) {
    let _baseUrl = baseURL;

    this.VerifyLogin = (callback) => {
        try {
            callEndPoint(`${_baseUrl}auth/verify`, true, (response) => {
                if (response) {
                    GetTokens((response) => {
                        callback(response)
                    })
                } else {
                    callEndPoint(`${_baseUrl}auth/challenge`, false, (response) => {
                        followUrl(response.url, (response) => {
                            window.location.href = response.url
                        });
                    });
                }
            })
        } catch (ex) {
            console.error(ex);
            callback(false);
        }
    }


    // Default endpoint caller - Can return either boolean or response context
    const callEndPoint = async (url, asBool = false, callback) => {
        try {
            var response = await fetch(url);
            if (asBool) {
                if (response.status !== 401) {
                    callback(true)
                } else {
                    callback(false)
                }
            } else {
                callback(response);
            }
        } catch (ex) {
            console.error(ex);
        }
    }


    // Challenge trailer
    const followUrl = async (url, callback) => {
        try {
            const response = await fetch(url, { redirect: 'follow' })
            if (response.redirected) {
                followUrl(response.url, callback);
            } else {
                callback(response);
            }
        } catch (ex) {
            console.error(ex);
        }
    }


    // Get tokens for Cookies
    const GetTokens = (callback) => {
        try {
            fetchTokens(`${_baseUrl}auth/login`, (r) => {
                applyToStorage(r, () => {
                    callback(true);
                })
            })
        } catch (ex) {
            console.error(ex);
            callback(false);
        }
    }


    // Endpoint caller for tokens
    const fetchTokens = async (url, callback) => {
        try {
            var response = await fetch(url);
            const json = await response.json();
            callback(json);

        } catch (ex) {
            console.error(ex);
        }
    }

    // Apply tokens to storage
    const applyToStorage = (json, callback) => {
        Object.keys(json).forEach(element => {
            window.sessionStorage.setItem(element, json[element]);
        });
        callback();
    }
}


// const VerifyLogin = (baseurl,callback) => {
//     try {
//         callEndPoint(`${baseurl}/auth/verify`,true,(response)=>{
//             if(response){
//                 GetTokens((response)=>{
//                     callback(response)
//                 })
//             } else {
//                 callEndPoint(`${baseurl}/auth/challenge`,false,(response)=>{
//                     followUrl(response.url,(response)=>{
//                         window.location.href = response.url
//                     });
//                 });
//             }
//         })
//     } catch(ex){
//         console.error(ex);
//         callback(false);
//     }
// }
