import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";

import CircularProgress from '@material-ui/core/CircularProgress';

const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "14px",
    }
})(Tooltip);

function ClassificationPanel({station}) {

    if (!station.classifications)
        return(<CircularProgress />)

    var data = station.classifications[0];
    
    var tooltipText = ""

    var thisYear = new Date().getFullYear()

    if (data.klassifikation === "Yellow") {
        tooltipText = "Ikke tilstrækkelig med analyser"
    }

    return (
        <div>
            <TableContainer component={Paper} style={{ height: "190px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "5px 10px 5px 10px" }}>
                    <Typography>Løbende klassifikation {thisYear}</Typography>
                </div>
                <Table size="small" >
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <CustomTooltip title={tooltipText} interactive>
                                    <SvgIcon>
                                        <circle cx='10' cy='10' r='8' stroke='black' strokeWidth='1' fill={data.klassifikation} />
                                    </SvgIcon>
                                </CustomTooltip>
                            </TableCell>
                            <TableCell>Antal Prøver</TableCell>
                            <TableCell>90% Percentil</TableCell>
                            <TableCell>95% Percentil</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>E.coli</TableCell>
                            <TableCell>{data.count}</TableCell>
                            <TableCell>{data.p90ecoli}</TableCell>
                            <TableCell>{data.p95ecoli}</TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell>Int. Ent.</TableCell>
                            <TableCell>{data.count}</TableCell>
                            <TableCell>{data.p90entero}</TableCell>
                            <TableCell>{data.p95entero}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ClassificationPanel

