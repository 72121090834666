import React,{ useState } from 'react'
import ExaminationGrid from './ExaminationGrid'
import { makeStyles, styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { red, green } from '@material-ui/core/colors';

import {
    Grid,
    Backdrop,
    Paper,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    CircularProgress
} from '@material-ui/core';

import { 
    AlertSnackbar,
    ConfirmDialogInput
 } from '..';

const useStyles = makeStyles((theme) => ({
    paper: {
        width:'95vw',
        height: '85vh'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const RedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
    marginLeft:"35px",
}));

const GreenButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
}));

const defAlert = {
    open:false,
    severity:'info',
    txt:''
}

function ExaminationPopup({ analysis, show, title, handleClose, handleApproveBtn, handleRejectBtn }) {
    const classes = useStyles();

    const [selection, setSelection] = useState([])
    const [alertSettings, setAlertSettings] = useState(defAlert)
    const [open, setOpen] = useState(false)
    const [reason, setReason] = useState('')

    const changeSelection = (arr) => {
        setSelection(arr)
    }

    const DoApproval = () => {
        if(selection.length === 0 ){setAlertSettings({open:true,txt:'Der er ingen prøver valgte.',level:'warning'});return}

        // Check that atleast one sample is checked
        handleApproveBtn(selection)
    }
    const finalizeReject = () => {
        handleRejectBtn({examinationId: selection[0], reason: reason})
        setReason('')
        setOpen(false)
    }

    const DoReject = () => {
        // Check that atleast one sample is checked
        if(selection.length === 0 ){setAlertSettings({open:true,txt:'Der er ingen prøver valgte.',level:'warning'});return}
        // Check that only one sample is checked
        if(selection.length > 1 ){setAlertSettings({open:true,txt:'Man kan kun afvise én prøve ad gangen.',level:'warning'});return}
        setOpen(true)
    }

    const handleOnChange = (ctx)=>{
        setReason(ctx)
    }
    const handleCancel = () => {
        setReason('')
        setOpen(false)
    }


    if (!show) {
        return <div></div>
    }

    if (!analysis) {
        return (
            <Dialog open={show} onClose={handleClose}>
                <CircularProgress />
            </Dialog>
        )
    } else {
        return (
            <Backdrop className={classes.backdrop} open={show}>
                <Paper className={classes.paper}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Grid 
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" className={classes.title}>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <GreenButton disabled variant="contained" color="primary" onClick={()=>{DoApproval()}}>Godkend prøver</GreenButton>
                                    <RedButton disabled variant="contained" color="secondary" onClick={()=>{DoReject()}}>Afvis prøve</RedButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    {/* <ExaminationTable items={analysis}/> */}
                    <ExaminationGrid items={analysis} callback={(arr)=>{changeSelection(arr)}} />
                </Paper>
                <AlertSnackbar toggled={alertSettings.open} severity={alertSettings.level} message={alertSettings.txt} closeCallback={()=>setAlertSettings(defAlert)}/>
                <ConfirmDialogInput context={{open:open, reason:reason, text: 'Begrund afvisning.'}} onChange={(ctx)=>{handleOnChange(ctx)}} onAccept={()=>{finalizeReject()}} onCancel={()=>{handleCancel()}} />
            </Backdrop>
        )
    }
}

export default ExaminationPopup
