import React, { useReducer, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Backdrop,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Snackbar,
  IconButton,
} from '@material-ui/core';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CloseIcon from '@material-ui/icons/Close'

import MuiAlert from '@material-ui/lab/Alert';

import { reducer, ACTIONS } from './reducer.js';
import {
  PollutionGrid,
  PollutionTypeSelector,
  PollutionSummary
} from './index'

import { ConfirmDialog } from '../index'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1500px',
    minWidth: '1000px',
    width: '95vw',
    height: '75vh'
  },
  paper: {
    padding: '20px',
    width: '100%',
    height: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Vælge prøver.', 'Vælge ordinær prøve.', 'Vælge omprøve.', 'Vælge erstatningsprøve.', 'Godkend.'];
}

function getStepHeader(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Vælge analyser der skal indgå i indberegningen.';
    case 1:
      return 'Vælge ordinære prøve.';
    case 2:
      return 'Vælge omprøve.';
    case 3:
      return 'Vælge erstatningsprøve.';
    case 4:
      return 'Godkend det valgte.';
    default:
      return 'Error..';
  }
}

var formTemplate = {
  bathingwaterStationId: "",
  year: new Date().getFullYear(),
  sampleId: "",
  confirmationSampleId: "",
  replacementSampleId: "",
  measures: null
}

export default function StepForm({ data, open, year, onAccept, onCancel }) {
  const classes = useStyles();

  const [state, dispatch] = useReducer(reducer, {
    data: [],
    activeStep: 0,
    confirmBox: {
      open: false,
      text:''
    },
    selected: [],
    error: {
      text: '',
      severity: '',
      open: false
    },
    form: formTemplate,
    confirmContent: []
  })


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const steps = getSteps();

  const toggleConfirmation = (txt) => {
    dispatch({ type: ACTIONS.TOGGLE_CONFIRM, payload: txt})
  }

  const handleCancel = () => {
    onCancel()
    resetStep()
  }

  const handleFinish = () => {
    onAccept(state.form)
    resetStep()
  }

  const nextStep = () => {
    if (state.activeStep === 0) {
      if (state.selected.length < 3 || state.selected.length > 3) {
        dispatch({ type: ACTIONS.SHOW_ERROR, payload: { text: `Der skal vælges tre prøver før man kan gå videre.`, severity: 'warning' } })
        return;
      }
      dispatch({ type: ACTIONS.SET_CONFIRM_CONTENT, payload: state.selected })
    }

    if (state.activeStep === 1) {
      if (state.form.sampleId === '') {
        dispatch({ type: ACTIONS.SHOW_ERROR, payload: { text: 'Vælge den ordinære prøve, før du kan gå videre.', severity: 'warning' } })
        return;
      }
      dispatch({ type: ACTIONS.REMOVE_SELECTED, payload: state.form.sampleId })
    }

    if (state.activeStep === 2) {
      if (state.form.confirmationSampleId === '') {
        dispatch({ type: ACTIONS.SHOW_ERROR, payload: { text: 'Vælge den omprøve, før du kan gå videre.', severity: 'warning' } });
        return;
      }
      dispatch({ type: ACTIONS.REMOVE_SELECTED, payload: state.form.confirmationSampleId })
    }

    if (state.activeStep === 3) {
      if (state.form.replacementSampleId === '') {
        dispatch({ type: ACTIONS.SHOW_ERROR, payload: { text: 'Vælge den erstatningsprøve, før du kan gå videre.', severity: 'warning' } });
        return;
      }
      dispatch({ type: ACTIONS.REMOVE_SELECTED, payload: state.form.replacementSampleId })
    }

    dispatch({ type: ACTIONS.SET_STEP, payload: state.activeStep + 1 })
  }

  const resetStep = () => {
    //Reset form
    dispatch({ type: ACTIONS.RESET_FORM, payload: formTemplate })
    dispatch({ type: ACTIONS.SET_STEP, payload: 0 })
  }

  useEffect(() => {
    formTemplate.bathingwaterStationId = data[0]?.observationFacilityId
    formTemplate.year = year
    var filteredData = data.filter(datapoint => { return parseInt(datapoint.date.substring(0, 4)) === year })
    dispatch({ type: ACTIONS.SET_DEFAULT, payload: formTemplate })
    dispatch({ type: ACTIONS.SET_DATA, payload: filteredData })
  }, [data])

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <IconButton edge="start" color="inherit" onClick={()=>handleCancel()} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4">Indberetning af korttidsforurening.</Typography>
          <div>
            <div>
              <Typography className={classes.instructions}>{getStepHeader(state.activeStep)}</Typography>
            </div>
            <div>
              {
                // Prøve valg
                state.activeStep === 0 ?
                  <PollutionGrid
                    items={state.data}
                    callback={(target) => {
                      dispatch({ type: ACTIONS.SET_SELECTED, payload: target })
                    }}
                  /> : null
              }
              {
                // Prøvetype
                state.activeStep === 1 ?
                  <PollutionTypeSelector
                    items={state.selected}
                    form={state.form}
                    type="sampleId"
                    callback={(type, id) => {
                      dispatch({ type: ACTIONS.SET_FORM, payload: { type: type, id: id } });
                    }}
                  />
                  : null
              }
              {
                // Prøvetype
                state.activeStep === 2 ?
                  <PollutionTypeSelector
                    items={state.selected}
                    form={state.form}
                    type="confirmationSampleId"
                    callback={(type, id) => {
                      dispatch({ type: ACTIONS.SET_FORM, payload: { type: type, id: id } });
                    }}
                  /> : null
              }
              {
                // Prøvetype
                state.activeStep === 3 ?
                  <PollutionTypeSelector
                    items={state.selected}
                    form={state.form}
                    type="replacementSampleId"
                    callback={(type, id) => {
                      dispatch({ type: ACTIONS.SET_FORM, payload: { type: type, id: id } });
                    }}
                  /> : null
              }
              {
                // Godkend
                state.activeStep === 4 ?
                  <PollutionSummary
                    form={state.form}
                    items={state.confirmContent}
                  /> : null
              }
            </div>
          </div>
          <Stepper activeStep={state.activeStep} alternativeLabel orientation="horizontal">
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {
              state.activeStep === steps.length - 1 ?
                <Button className={classes.button} variant="contained" color="primary" onClick={() => toggleConfirmation("Er du sikker på at du vil gemme?")}>
                  Godkend
                </Button>
                :
                <Button className={classes.button} variant="contained" color="primary" onClick={() => nextStep()}>
                  Næste
                </Button>
            }
            {state.activeStep > 0 ?
              <Button className={classes.button} variant="contained" color="secondary" startIcon={<RotateLeftIcon />} onClick={() => resetStep()}>
                Start forfra
              </Button>
              : <></>
            }
          </div>
        </Paper>
      </Box>
      <Snackbar open={state.error.open} autoHideDuration={6000} onClose={() => dispatch({ type: ACTIONS.HIDE_ERROR })}>
        <Alert severity={state.error.severity}>
          {state.error.text}
        </Alert>
      </Snackbar>
      <ConfirmDialog context={state.confirmBox} onAccept={()=>handleFinish()} onCancel={()=>dispatch({type: ACTIONS.TOGGLE_CONFIRM, payload:''})} />
    </Backdrop>
  );
}
