import React, { useState, useEffect } from 'react'

import {
    Card,
    CardContent,
    Grid,
    Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '50vh'
    },
    card: {
        minWidth: 275,
    }
}));

export default function PollutionSummary({ form, items }) {
    const [state, setState] = useState([])
    const [context, setContext] = useState(<div></div>)

    const classes = useStyles();


    const mapSelection = (obj, itemList) => {
        Object.keys(obj).map(key => {

            itemList.map(x => {
                if (x.examinationId === obj[key]) {
                    x.type = key
                }
                return x
            })
        })
        return itemList
    }

    useEffect(() => {
        var newState = mapSelection(form, items)
        setState(newState)
    }, [form, items])

    useEffect(() => {
        var ctx = state.map((obj,i) => {
            if(obj.type === "sampleId")
                obj.type = "Ordinær prøve"
            if(obj.type === "confirmationSampleId")
                obj.type = 'Omprøve'
            if(obj.type === 'replacementSampleId')
                obj.type = 'Erstatningsprøve'
            return (
                <Grid item key={`grid-obj-row-${i}`}>
                    <Card className={classes.card} key={`card-${i}`}>
                        <CardContent>
                            <Typography key={`card-header-${i}`} variant="h5">{obj.type}</Typography>
                            <Typography key={`card-date-${i}`} variant="body2" component="p">{obj.displayDate}</Typography>
                            <Typography key={`card-ecoli-${i}`} variant="body2" component="p">E. Coli: {obj['E.coli']}</Typography>
                            <Typography key={`card-entero-${i}`} variant="body2" component="p">Int. Enterokokker: {obj['Intestinal enterokokker']}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )
        })
        setContext(ctx);
    }, [state])

    return (
        <Grid container className={classes.root} direction="row" justify="center" alignItems="center" spacing={5}>
            {context}
        </Grid>
    )
}