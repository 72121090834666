import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';

import { BrowserRouter as Router, Redirect } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';

//import reportWebVitals from './reportWebVitals';

const baseName = process.env.BASE_NAME;

const MOUNT_NODE = document.getElementById('root');

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0D3958',
    },
    secondary: blueGrey,
  },
});

ReactDOM.render(
  <Router basename={baseName}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Router>,
  MOUNT_NODE,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
