import React, { useState } from 'react'
import Fab from '@material-ui/core/Fab';
import DescriptionIcon from '@material-ui/icons/Description';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import createReport from 'docx-templates';


const useStyles = makeStyles((theme) => ({
    form: {
        margin: theme.spacing(3),
        width: '450px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputField: {
        width: '160px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    inputHolder: {
        marginLeft: '15px',
        float: 'left'
    }
}));

const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "14px",
    }
})(Tooltip);

var freshlevels = {
    cG: 500, //E.coli green
    cY: 2000, //E.coli yellow
    eG: 200, //Entero green
    eY: 800 //Entero yellow
}

var saltlevels = {
    cG: 250, //E.coli green
    cY: 1000, //E.coli yellow
    eG: 100, //Entero green
    eY: 400 //Entero yellow
}

function BathingwaterReport({ station }) {
    const [open, setOpen] = useState(false);
    const [workingOn, setWorkingOn] = useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(true);
        loadTemplate(station.blueFlag, station.waterType, (template) => {
            console.log('Template loaded')

            let levels = station.waterType === "Freshwater" ? freshlevels : saltlevels;

            setWorkingOn(station.name)

            let dataObject = structureData(station.data, levels)
            console.log('Data formated')
            dataObject = Object.assign(...dataObject, { strand: station.name }, levels);

            if (template && dataObject) {
                fillTemplate(template, dataObject, () => {
                    setOpen(false);
                })
            }
        });
    };
    const classes = useStyles()

    return (
        <div>
            <Fab size="medium" onClick={()=>handleToggle()} style={{ marginTop: '15px' }}>
                <CustomTooltip title='Generer badevandsrapporter.' interactive size="12px">
                    <DescriptionIcon />
                </CustomTooltip>
            </Fab>

            <Backdrop className={classes.backdrop} open={open} onClick={()=>handleClose()}>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}>
                    <Grid key={2} item align='center'>
                        <Paper style={{ width: '15vw', height: '15vh' }}>
                            <br />
                            <CircularProgress />
                            <br />
                            <Typography variant="caption" align='center'>
                                Arbejder på rapport for {workingOn}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Backdrop>
        </div>
    )
}

export default BathingwaterReport

async function loadTemplate(flag, water, fun) {

    async function fetchTemplate(url, callback) {
        const response = await fetch(url);
        const res = await response.arrayBuffer()
        callback(res)
    }

    flag = flag ? "bf_" : "nonbf_"
    water = water === "Freshwater" ? "inland" : "marine"
    var fileUrl = 'custom/templates/' + flag + water + '.docx'

    await fetchTemplate(fileUrl, (template) => {
        fun(template)
    })
}

function structureData(data, levels) {
    var cutData = data.filter(element => element.year === new Date().getFullYear())
    var dates = cutData.map(data=>{return data["date"]})
    dates = dates.map(date => {
        return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1)
    })

   

    var dataObj = [];

    for (let index = 1; index <= 20; index++) {
        var workingData = cutData[index-1]

        if(workingData){
            var ecoli = workingData.hasOwnProperty("Escherichia.coli") ? workingData["Escherichia.coli"] : ""
            var ecoliAttr = workingData.hasOwnProperty("Escherichia.coli-attr") ? workingData["Escherichia.coli-attr"] : ""
            var entero = workingData.hasOwnProperty("Intestinal enterokokker") ? workingData["Intestinal enterokokker"] : ""
            var enteroAttr = workingData.hasOwnProperty("Intestinal enterokokker-attr") ? workingData["Intestinal enterokokker-attr"] : ""
            var temp = workingData.hasOwnProperty("Vandtemperatur") ? workingData["Vandtemperatur"] : ""
            var date = workingData.hasOwnProperty("date") ? new Date(workingData["date"]).getDate() + "/" + (new Date(workingData["date"]).getMonth() + 1) : ""
        } else {
            var ecoli = ""
            var ecoliAttr = ""
            var entero = ""
            var enteroAttr = ""
            var temp = ""
            var date = ""
        }

        
        dataObj.push(
            {
                ['d' + index]: date,
                ['t' + index]: temp,
                ['cg' + index]: ecoli ? ecoli < levels.cG ? `${ecoliAttr}${ecoli}` : '' : '',
                ['cy' + index]: ecoli ? ecoli >= levels.cG && ecoli <= levels.cY ? `${ecoliAttr}${ecoli}`: '' : '',
                ['cr' + index]: ecoli ? ecoli > levels.cY ? `${ecoliAttr}${ecoli}` : '' : '',
                ['eg' + index]: entero ? entero < levels.eG ? `${enteroAttr}${entero}` : '' : '',
                ['ey' + index]: entero ? entero >= levels.eG && entero <= levels.eY ? `${enteroAttr}${entero}` : '' : '',
                ['er' + index]: entero ? entero > levels.eY ? `${enteroAttr}${entero}` : '' : ''
            }
        )
    }

    // console.log({"dataObj2":dataObj2})

    // // Generate placeholders mapping to template
    // var spaces = new Array(20).fill(1).map((_, i) => i + 1)

    // var ecoli = cutData.map(data=>{return data["Escherichia.coli"]})
    // var entero = cutData.map(data=>{return data["Intestinal enterokokker"]})
    // var temp = cutData.map(data=>{return data["Vandtemperatur"]})
    // var dates = cutData.map(data=>{return data["date"]})
    // dates = dates.map(date => {
    //     return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1)
    // })

    // var dataObj = spaces.map((element, index) => {
    //     return ({
    //         ['d' + element]: dates[index] ? dates[index] : '',
    //         ['t' + element]: temp[index] ? temp[index] : '',
    //         ['cg' + element]: ecoli[index] ? ecoli[index] < levels.cG ? ecoli[index] : '' : '',
    //         ['cy' + element]: ecoli[index] ? ecoli[index] >= levels.cG && ecoli[index] <= levels.cY ? ecoli[index] : '' : '',
    //         ['cr' + element]: ecoli[index] ? ecoli[index] > levels.cY ? ecoli[index] : '' : '',
    //         ['eg' + element]: entero[index] ? entero[index] < levels.eG ? entero[index] : '' : '',
    //         ['ey' + element]: entero[index] ? entero[index] >= levels.eG && entero[index] <= levels.eY ? entero[index] : '' : '',
    //         ['er' + element]: entero[index] ? entero[index] > levels.eY ? entero[index] : '' : ''
    //     })
    // })

    return (dataObj)
}


async function fillTemplate(template, data, callback) {
    const report = await createReport({
        template,
        data,
        additionalJsContext: {
            year: new Date().getFullYear()
        }
    });

    var blob = new Blob([report], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    saveDataToFile(
        blob,
        'report.docx'
    );

    callback("ok")
}

const saveDataToFile = (blob, fileName, mimeType) => {
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, fileName, mimeType);
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 1000);
};

const downloadURL = (data, fileName) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
};