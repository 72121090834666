import React, { useEffect, useState } from 'react';

import { Switch, Route, Link, useHistory } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  Box,
  AppBar,
  Tab,
  Toolbar,
} from '@material-ui/core';

import {
  TabContext,
  TabList,
  TabPanel
} from '@material-ui/lab'

import {
  Overview,
  Pollutions,
} from '../index';

import { LoadingOverlay } from '../../components'

import restClient from '../../utilities/api';
import MermaidLogo from '../../assets/img/logo.png';

import LoginProcess from '../../utilities/LoginProcess';
import DevLogin from '../../utilities/DevLoginProcess';

function UserException(message) {
  this.message = message;
  this.name = 'UserException';
}

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 3,
  },
  content: {
    // flexGrow: 1,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'transparent',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    height: '64px',
  },
  dhiLogo: {
    width: '10rem',
    height: 'auto',
    marginLeft: '0.5rem',
  },
  tab: {
    height: '64px',
  },
  background: {
    backgroundColor: '#565656'
  },
}));

const tabs = [
  {
    value: '/overview',
    label: 'Oversigt',
    to: '/overview',
  },
  {
    value: '/short-term-pollutions',
    label: 'Korttidsforurening',
    to: '/short-term-pollutions',
  }
];

const App = () => {
  const classes = useStyles();
  const history = useHistory();

  const [ready, setReady] = useState(false);
  const [value, setValue] = useState(tabs[0].value);
  const [stations, setStations] = useState(null);
  const [user, setUser] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState({text:'Verificer',isError:false})

  useEffect(() => {
    if(process.env.NODE_ENV === "development"){
      new DevLogin().initiateLogin((result)=>{
        initalLoad((userObj, stationsArray) => {
            setStations(stationsArray);
            setUser(userObj)
            setReady(result);
            history.push('/overview')
        })
      })
    } else {
      new LoginProcess(process.env.REACT_APP_API_URL).VerifyLogin((result) => {
        initalLoad((userObj, stationsArray) => {
          setStations(stationsArray);
          setUser(userObj)
          setReady(result);
          history.push('/overview')
        })
      })
    }
  }, [])

  const initalLoad = async (callback) => {
    try{
      var pulsClient = new restClient(process.env.REACT_APP_DATA_URL);
      var apiClient = new restClient(process.env.REACT_APP_API_URL);

      setLoadingStatus({text:"Indlæser bruger indstillinger.",isError:false})
      let settingsResponse = await apiClient.GetSettings();
      var userSettings = settingsResponse.data;

      if(!userSettings.access){
        setLoadingStatus({text:"Ingen adgang.\n Kontakt DHI.",isError:true});
        return;
      }
  
      setLoadingStatus({text:"Indlæser profil fra PULS.",isError:false})
      let profileResponse = await pulsClient.GetPulsProfile();
      let profile = profileResponse.data
  
      if (profile.favorites.length < 1){
        setLoadingStatus({text:"Din favoritliste er tom.",isError:true})
        return;
      }

      setLoadingStatus({text:"Indlæser stationer",isError:false})
      profile.favorites = profile.favorites.filter(favorit => { return favorit.observationFacilityType == 'BathingwaterStation' })
      let stations = await pulsClient.GetStations(profile.favorites);

      callback(userSettings, stations)
  
    } catch(ex){
      console.error(ex)
    }
  }


  return (
    <Box className={classes.background}>
      <TabContext value={value}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Box display="flex" alignItems="baseline">
                  <img
                    className={classes.dhiLogo}
                    src={MermaidLogo}
                    alt="logo"
                    id="logo"
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" ml="auto" mr={0}>
              <TabList onChange={(e, v) => setValue(v)}>
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    className={classes.tab}
                    value={tab.value}
                    label={tab.label}
                    component={Link}
                    to={tab.to}
                  />
                ))}
              </TabList>
            </Box>
          </Toolbar>
        </AppBar>
      </TabContext>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {
          !ready ?
            <LoadingOverlay text={loadingStatus.text} isError={loadingStatus.isError} />
            :
            <Switch>
              <Route path="/overview">
                <Overview user={user} stations={stations} />
              </Route>
              <Route path="/short-term-pollutions">
                <Pollutions user={user} stations={stations} />
              </Route>
            </Switch>
        }
      </main>
    </Box>
  );
};

export default App;
