import React from 'react'

import {
    Snackbar
} from '@material-ui/core'

import {
    Alert
} from '@material-ui/lab'

export default function AlertSnackbar({toggled, severity, message, closeCallback}) {
    return (
        <Snackbar
            open={toggled}
            anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}
            autoHideDuration={6000}
            onClose={() => { closeCallback(false)}}
        >
            <Alert
                severity={severity}
                onClose={() => { closeCallback(false)}}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}