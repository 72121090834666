import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Button,
    Backdrop,
    Grid,
    Paper,
    FormLabel,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
    Tooltip,
    Fab
} from '@material-ui/core'

import { red } from '@material-ui/core/colors';

import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '550px',
    },
    gridInputContainer:{
        padding: theme.spacing(3),
    },
    gridButtonContainer:{
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    inputField: {
        maxWidth: '160px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "14px",
    }
})(Tooltip);

const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(Button);

//export default function SettingsDialog({ mapOptions, userOptions, onInputChange, onCloseSettings }) {
export default function SettingsDialog({ mapOptions, userOptions, onConfirm, onCancel }) {

    const [open, setOpen] = useState(false);
    const [settingsHolder, setUserSettings] = useState(userOptions)

    const classes = useStyles()

    const confirmAction = () => {
        setOpen(false);
        onConfirm(settingsHolder)
    }

    const cancelAction = () => {
        setOpen(false);
        setUserSettings(userOptions)
        onCancel()
    }

    const handleInputChange = (e) => {
        setUserSettings((prev)=>{
            return{
                ...prev,
                [e.target.name]: parseInt(e.target.value)
            }
        })
    }

    const handleMapChange = (e) => {
        setUserSettings((prev)=>{
            return{
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleToggle = () => {
        setOpen(true);
    };

    useEffect(()=>{
        setUserSettings(userOptions)
    },[userOptions])
    


    if (!settingsHolder || !mapOptions) {
        return (<div></div>)
    }

    var RadioItems = mapOptions.map((ctx) => {
        return <FormControlLabel key={ctx.name} value={ctx.name} control={<Radio />} label={ctx.name} />
    })

    return (
        <>
            <Fab size="medium" onClick={handleToggle} style={{ marginTop: '15px' }}>
                <CustomTooltip title='Indstillinger.' interactive size="12px">
                    <SettingsIcon />
                </CustomTooltip>
            </Fab>
            <Backdrop className={classes.backdrop} open={open}>
                <Paper className={classes.root}>
                    <Grid 
                        container 
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.gridInputContainer}
                    >
                        <Grid item xs={4}>
                            <FormControl>
                                <FormLabel component='legend'>Kort-layout</FormLabel>
                                <RadioGroup aria-label='kort' name='mapStyle' value={settingsHolder.mapStyle} onChange={(e)=>handleMapChange(e)}>
                                    {RadioItems}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel component='legend'>Grænseværdier - Saltvand</FormLabel>
                            <>
                                <FormHelperText id="marineEcoli-helper-text">E.Coli</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="marineEcoli"
                                    name="marineEcoli"
                                    value={settingsHolder.marineEcoli}
                                    onChange={(e)=>handleInputChange(e)}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="marineEcoli-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                                <FormHelperText id="marineEntro-helper-text">Enterococcus</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="marineEntro"
                                    name="marineEntro"
                                    value={settingsHolder.marineEntro}
                                    onChange={(e)=>handleInputChange(e)}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="marineEntro-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                            </>
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel component='legend'>Grænseværdier - Ferskvand</FormLabel>
                            <>
                                <FormHelperText id="freshEcoli-helper-text">E.Coli</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="freshEcoli"
                                    name="freshEcoli"
                                    value={settingsHolder.freshEcoli}
                                    onChange={(e)=>handleInputChange(e)}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="freshEcoli-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                                <FormHelperText id="freshEntro-helper-text">Enterococcus</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="freshEntro"
                                    name="freshEntro"
                                    value={settingsHolder.freshEntro}
                                    onChange={(e)=>handleInputChange(e)}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="freshEntro-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                            </>
                        </Grid>
                        
                    </Grid>
                    <Grid
                        container
                        className={classes.gridButtonContainer}
                    >
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" onClick={()=>{confirmAction()}}>Gem</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <RedButton variant="contained" onClick={()=>{cancelAction()}}>Afbryd</RedButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Backdrop>
        </>
    )
}








/*
if (!userOptions || !mapOptions) {
        return (<div></div>)
    }

    var RadioItems = mapOptions.map((ctx) => {
        return <FormControlLabel key={ctx.name} value={ctx.name} control={<Radio />} label={ctx.name} />
    })

    return (
        <>
            <Fab size="medium" onClick={handleToggle} style={{ marginTop: '15px' }}>
                <CustomTooltip title='Indstillinger.' interactive size="12px">
                    <SettingsIcon />
                </CustomTooltip>
            </Fab>
            <Backdrop className={classes.backdrop} open={open}>
                <Paper className={classes.root}>
                    <Grid 
                        container 
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.gridInputContainer}
                    >
                        <Grid item xs={4}>
                            <FormControl>
                                <FormLabel component='legend'>Kort-layout</FormLabel>
                                <RadioGroup aria-label='kort' name='mapStyle' value={userOptions.mapStyle} onChange={onInputChange()}>
                                    {RadioItems}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel component='legend'>Grænseværdier - Saltvand</FormLabel>
                            <>
                                <FormHelperText id="marineEcoli-helper-text">E.Coli</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="marineEcoli"
                                    name="marineEcoli"
                                    value={userOptions.marineEcoli}
                                    onChange={onInputChange()}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="marineEcoli-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                                <FormHelperText id="marineEntro-helper-text">Enterococcus</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="marineEntro"
                                    name="marineEntro"
                                    value={userOptions.marineEntro}
                                    onChange={onInputChange()}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="marineEntro-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                            </>
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel component='legend'>Grænseværdier - Ferskvand</FormLabel>
                            <>
                                <FormHelperText id="freshEcoli-helper-text">E.Coli</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="freshEcoli"
                                    name="freshEcoli"
                                    value={userOptions.freshEcoli}
                                    onChange={onInputChange()}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="freshEcoli-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                                <FormHelperText id="freshEntro-helper-text">Enterococcus</FormHelperText>
                                <OutlinedInput
                                    className={classes.inputField}
                                    id="freshEntro"
                                    name="freshEntro"
                                    value={userOptions.freshEntro}
                                    onChange={onInputChange()}
                                    type="number"
                                    endAdornment={<InputAdornment position="end">mg/l</InputAdornment>}
                                    aria-describedby="freshEntro-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />
                            </>
                        </Grid>
                        
                    </Grid>
                    <Grid
                        container
                        className={classes.gridButtonContainer}
                    >
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" onClick={handleClose}>Gem</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <RedButton variant="contained" onClick={handleClose}>Afbryd</RedButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Backdrop>
        </>
    )
*/