import React from 'react'
import { Tooltip, Fab } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
    }
})(Tooltip);

export default function ActionButton({tooltip,icon,callback}){
    return(
        <Fab size="medium" onClick={()=>callback()} style={{ marginTop: '15px' }}>
            <CustomTooltip title={tooltip}>
                {icon}
            </CustomTooltip>
        </Fab>
    )
}