import React from 'react'

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function CustomSelect({selected, options, callback }) {
    const classes = useStyles();


    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="simple-select-label">Årstal</InputLabel>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={selected}
                    onChange={(event)=>callback(event.target.value)}
                >
                    {options.map((opt,i)=>{
                        return(<MenuItem key={`opt-key-${i}`} value={opt}>{opt}</MenuItem>)
                    })}
                </Select>
            </FormControl>

        </div>

    )
}