import React from "react"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';

export default function StationInfo({station}) {
    
    if(!station){ 
        return(
            <CircularProgress />
        )
    }

    var flag = station.blueFlag ? "Ja" : "Nej"
    var vandtype = station.waterType === "Freshwater" ? "Sø" : "Hav"
    var stationsType = station.inspectionStation ? "Kontrolstation" : "Badevandsstation"
    var dkbw = station.dkbw === 0 ? "-" : station.dkbw
    

    return(
        <TableContainer component={Paper} style={{height: "190px"}}>
            <Typography style={{margin: "5px 0px 5px 10px"}}>Stationsinformation</Typography>
            <Table size="small" >
                <TableBody>
                    <TableRow>
                        <TableCell>Navn:</TableCell>  
                        <TableCell style={{ minWidth: "220px" }}>{station.name}</TableCell>
                        <TableCell>DKBW:</TableCell>
                        <TableCell>{dkbw}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Hav eller sø:</TableCell>
                        <TableCell>{vandtype}</TableCell>
                        <TableCell>Oprettet:</TableCell>
                        <TableCell>{station.started.split("T")[0]}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Stationstype:</TableCell>
                        <TableCell>{stationsType}</TableCell>
                        <TableCell>Længdegrad:</TableCell>
                        <TableCell>{station.position.longitude.toString().substring(0,6)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Blåflag:</TableCell>
                        <TableCell>{flag}</TableCell>
                        <TableCell>Breddegrad:</TableCell>
                        <TableCell>{station.position.latitude.toString().substring(0,6)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}