import React from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core/styles';

export default function PollutionGrid({ items, callback }) {
    const classes = useStyles();

    var colDef = [
        "displayDate",
        "E.coli",
        "Intestinal enterokokker",
        "examinationId"
    ]

    var colHeaders = [
        "Dato",
        "E. Coli",
        "Intestinal enterokokker",
        "Prøve Id"
    ]

    var dataColumns = colHeaders.map((col, i) => {
        return (
            {
                field: colDef[i],
                headerName: col,
                flex:1,
            }
        )
    })

    var dataRows = items.map((item, i) => {

        var returnObj = { id: i }

        for (let index = 0; index < colDef.length; index++) {
            returnObj[colDef[index]] = item[colDef[index]]
        }
        return (returnObj)
    })


    return (
        <div style={{ height: "47vh"}}>
            <DataGrid
                checkboxSelection
                className={classes.root}
                pageSize={9}
                onSelectionModelChange={(target)=>{
                    const selectedIDs = new Set(target.selectionModel)
                    const selectedRowData = dataRows.filter((row)=>selectedIDs.has(row.id))
                    callback(selectedRowData)
                }}
                rows={dataRows}
                columns={dataColumns}
            />
        </div>
    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
        color:
            theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.type === 'light'
                    ? 'rgba(0,0,0,.85)'
                    : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        }
    },
}));
