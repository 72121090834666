/*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/

if(!Object.prototype.filter){
    function filter (predicate){
        const obj = this;
        if(!obj.__proto__){
            throw new Error('Cannot invoke the filter method: the input is not an object.')
        }
        const newObj = {}
        for (let prop in obj){
            if(obj.hasOwnProperty(prop) && predicate(obj[prop], prop, obj)){
                newObj[prop] = obj[prop]
            }
        }
        return newObj
    }
    Object.defineProperty(Object.prototype, 'filter', {
        value: filter,
        writable: true,
        configurable: true
    })
}


var classificationTable = {
    "Marine": {
        Entro: {
            Excellent: 100,
            Good: 200,
            Sufficient: 185
        },
        Ecoli: {
            Excellent: 250,
            Good: 500,
            Sufficient: 500
        }
    },
    "Freshwater": {
        Entro: {
            Excellent: 200,
            Good: 400,
            Sufficient: 330
        },
        Ecoli: {
            Excellent: 500,
            Good: 1000,
            Sufficient: 900
        }
    }
}


function status(obj,options){

    var newObj = obj

    newObj = obj.map(context => {
        var newStatus = "loading";

        if (context.data) {
            newStatus = "ok";
            // Get water type
            if (context.waterType === "Unknown") { context.icon = "warning"; return context };
            var ecoliTreshold = context.waterType === "Marine" ? options.marineEcoli : options.freshEcoli;
            var entroTreshold = context.waterType === "Marine" ? options.marineEntro : options.freshEntro;

            // Get latest analysis
            if (context.data.length > 0) {
                var sample = context.data[0]
                
                if (sample["E.coli"] >= ecoliTreshold || sample["Intestinal enterokokker"] >= entroTreshold) {
                    newStatus = "error";
                }

            } else {
                newStatus = "nodata"
            }
        }

        context.icon = newStatus
        return context;
    })
    return newObj
}

function pulsClassification(obj){
    if(!obj)
    {
        return [{
            year: new Date().getFullYear(),
            count: 0,
            count: 0,
            p90ecoli: 0,
            p90entero: 0,
            p95ecoli: 0,
            p95entero: 0,
            klassifikation: "#000000"
        }]
    }
    
    obj.map(element => {
        var classificationArray = element.assessments?.reverse().map(ctx=>{
            let c = "Yellow"
            if(ctx.classification == "Excellent"){c = "#1a91e0"}
            if(ctx.classification == "Good"){c = "#0ad61f"}
            if(ctx.classification == "Sufficient"){c = "#f7a90e"}
            if(ctx.classification == "Poor"){c = "#ff0000"}
            if(ctx.classification == "Unavailable"){c = "Yellow"}

            return {
                year: ctx.year,
                count: ctx.readings?.length,
                p90ecoli: parseFloat(ctx.percentile90?.escherichiaColi.toFixed(2)),
                p90entero: parseFloat(ctx.percentile90?.intestinalEnterococci.toFixed(2)),
                p95ecoli: parseFloat(ctx.percentile95?.escherichiaColi.toFixed(2)),
                p95entero: parseFloat(ctx.percentile95?.intestinalEnterococci.toFixed(2)),
                klassifikation: c
            }
        })
        element.classifications = classificationArray
        return element
    })

    return obj
}

function classification(obj){
    var newObj = obj

    newObj.features = obj.map(context => {

        var classificationArray = {};

        if (context.data){
            var type = context.waterType;
            if(type === "Unknown") type='Marine'

            var table = classificationTable.filter((value, key) => {
                return key === type;
            })
            
            var obj = restructureData(context.data);

            classificationArray = obj.map(element=>{return calcStatus(element,table)});
        }

        context.classifications = classificationArray;

        return context;
    })
    return newObj
}

function markData(data) {
    /*
        Denne skal rettes til - Der skal hentes short-term-pollutions seneste seks år
        Hvis en prøve er registreret der skal man finde prøven i listen og mærke dem hhv ordinær og erstatning
        Ordinær skal undlades i beregningen
        Omprøve skal ALTID undlades
        Erstatningsprøve skal benyttes
    */

    try {
        var newData = data;
        //isolate data from geojson
        newData = newData.map(stations => {
            if(stations.data && stations.schedules && stations.incidents){

                var dataObj = stations.data;
                var planObj = stations.schedules;
                var incidentObj = stations.incidents;

                // mark data from incidentObj
                incidentObj.forEach(element => {
                    if(element.examination){ dataObj.find(obj => obj.examinationId === element.examination.examinationId).incident = true; } //Added if -> Error in Puls if no examination
                    if(element.reExamination){ dataObj.find(obj => obj.examinationId === element.reExamination.examinationId).reExamination = true; }
                    if(element.replacementExamination){ dataObj.find(obj => obj.examinationId === element.replacementExamination.examinationId).replacementExamination = true; }
                    if(element.examinationDiscounted && element.replacementExamination){ dataObj.find(obj => obj.examinationId === element.examination.examinationId).discounted = true; } //remove replaced examinations
                    if(element.examinationDiscounted===false && element.replacementExamination){ dataObj.find(obj => obj.examinationId === element.replacementExamination.examinationId).discounted = true; } //remove replacement examinations
                });

                // mark data from plan that is accepted in classification
                dataObj = dataObj.map(examination => {
                    var sampleDay = new Date(examination.date);
                    var year = sampleDay.getFullYear();
                    var extended = planObj.find(obj => obj.year === year)
                    extended = extended ? extended.extended : null

                    var classificationPeriod = extended ? {
                        start: new Date(year + "-05-19T00:00:00+02:00"), end: new Date(year + "-09-15T00:00:00+02:00")
                    } : {
                        start: new Date(year + "-05-19T00:00:00+02:00"), end: new Date(year + "-08-31T00:00:00+02:00")
                    }
                    examination.year = year;
                    examination.classificationPeriod = classificationPeriod;

                    // Assume inclusion
                    examination.includeClassification = true;

                    // Exclude if Outside period
                    if (sampleDay <= classificationPeriod.start && sampleDay >= classificationPeriod.end) {
                        examination.includeClassification = false;
                    }
                    //Reexamination or excluded
                    if (examination.discounted) {
                        examination.includeClassification = false;
                    }

                    //Always exclude reExamination!
                    if (examination.sampleType === 43) {
                        examination.includeClassification = false;
                        examination.reExamination = true;
                    }

                    return examination
                })

                stations.data = dataObj
            }

            return stations
        })
   
        return newData

    } catch (ex) {
        console.log(ex);
        console.log(data)
    }
}

function restructureData(input){
    // reform data into:
    /*
        [
            {
                year: 2018,
                ecoli:[34,123,4,32,121.4,33]
                entro:[33,56,43.21,312,54,56]
                count: 6
            },
            {
                year: 2017,
                ecoli:[34,123,4,32,121.4,33]
                entro:[33,56,43.21,312,54,56]
                count: 6
            },

        ]

    */

    //Generate year array for classification inclusion
    let thisYear = parseInt(new Date().getFullYear())+1
    var classSequence = []
    for(let i=0;i<3;i++){
        thisYear = thisYear-1
        classSequence.push(Array.from(new Array(4),(val,index)=>thisYear-index))
    }

    var output = classSequence.map(seq => {
        var partial = seq.map(workingYear => {
            var filtered = input.filter(element => element.includeClassification && element.year === workingYear);
            // var ecoli = extractAnalysis(filtered,1229).filter(el=>{return el!=null});
            // var entero = extractAnalysis(filtered,1218).filter(el=>{return el!=null});
            var result =  {
                year: workingYear,
                ecoli: filtered.map(obj=>{return obj['E.coli']}),
                entero: filtered.map(obj=>{return obj['Intestinal enterokokker']})
            }
            return result
        })
        
        return partial
    })

    return output
}

function calcStatus(obj,limits){
    var returnArray = {}
    var thisYear = new Date().getFullYear()
    var classificationYear = obj[0].year;
    var count = obj.map(element => {return element.ecoli.length}).reduce((a,b)=>{return a + b})

    
    if(obj[0].ecoli.length < 1){
        returnArray.year = thisYear;
        returnArray.count = obj[0].ecoli.length;
        returnArray.p90ecoli = "-";
        returnArray.p90entero = "-";
        returnArray.p95ecoli = "-";
        returnArray.p95entero = "-";
        returnArray.klassifikation = "Yellow"
        return returnArray;
    }
    

    /*
    var testData = [30,45,30,30,30,30,30,30,30,30,30,30,30,6800,30,30,30,30,30,30,30,3200,30,30,30,12000,30,30,1500,30,30,30,30,4800,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30]
    let [testP90, testP95] = percentile(testData);
    console.log({"testP90": testP90, "testP95": testP95})
    */

    var ecoli = obj.map(element => {return element.ecoli}).reduce((a,b)=>{a = a.concat(b); return a},[])
    var entero = obj.map(element => {return element.entero}).reduce((a,b)=>{a = a.concat(b); return a},[])

    var limEcoli = limits[Object.keys(limits)].Ecoli;
    var limEntro = limits[Object.keys(limits)].Entro;

    [returnArray.p90ecoli,returnArray.p95ecoli] = percentile(ecoli);
    [returnArray.p90entero,returnArray.p95entero] = percentile(entero);
    returnArray.year = classificationYear;
    returnArray.count = count;

    if (returnArray.p95ecoli <= limEcoli.Excellent && returnArray.p95entero <= limEntro.Excellent) { returnArray.klassifikation = "#1a91e0"} //Blue
    else if (returnArray.p95ecoli <= limEcoli.Good && returnArray.p95entero <= limEntro.Good) { returnArray.klassifikation = "#0ad61f"} //Green
    else if (returnArray.p90ecoli <= limEcoli.Sufficient && returnArray.p90entero <= limEntro.Sufficient) { returnArray.klassifikation = "#f7a90e"} //Orange
    else { returnArray.klassifikation = "#ff0000" } // Red

    return returnArray
}

function percentile(arr) {
    if (arr.length === 0) return [99999,99999];
    
    //TEMP: REMOVE nulls instead of returning err.
    //if(arr.some(el => {return el === null || el === undefined})) return [99999,99999];
    arr = arr.filter(x => {return x != null});


    var log10 = arr.map(num => {
        return Math.log10(num)}),
        avg = log10.reduce((a,b) => a+b,0) / log10.length,
        std = Math.sqrt(log10.reduce((sq,n)=>{
            return sq + Math.pow(n - avg,2);
        },0) / (log10.length - 1));
    
    var P90 = Math.pow(10,(avg + 1.282 * std));
    var P95 = Math.pow(10,(avg + 1.65 * std));

    return [parseFloat(P90.toFixed(2)), parseFloat(P95.toFixed(2))];
}




function extractAnalysis(data = Object, stancode = Int32Array){
    var dataArray = data.map(examination => {
        
        var result = examination.sampling.sample.analyses.filter(params => {
            return params.parameter.value === stancode;
        })
        if(result.length < 1){return null}else{return result[0].value}
    })
    return dataArray;
}

function extractMeasurement(data = Object, stancode = Int32Array){
    var dataArray = data.map(examination => {
        
        var result = examination.measurements.filter(params => {
            return params.parameter.value === stancode;
        })
        if(result.length < 1){return null}else{return result[0].value}
    })
    return dataArray;
}

function extractDates(data = Object){
    var dataArray = data.map(examination => {
        return examination.sampling.samplingStarted
    })
    return dataArray;
}


export default {
    status,
    classification,
    pulsClassification,
    markData,
    extractAnalysis,
    extractMeasurement,
    extractDates
}