import axios from 'axios'
export default function restClient(url){
    // const axios = require('axios');
    let failedQueue = [];
    let isRefreshing = false;
    let api = axios.create({
        baseURL: url
    })

    // Request interceptor for API calls
    api.interceptors.request.use(
        async config => {
            const access_token = sessionStorage.getItem("access_token")
            config.headers = {
                'Authorization': `Bearer ${access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            return config;
        },
        error => {
            Promise.reject(error)
    })

    // // TODO: Must build refresh method in api first!!

    // // Response interceptor for API calls
    // api.interceptors.response.use(function (response) {
    //     return response;
    // }, async function (error) {

    //     const originalRequest = error.config;

    //     if (error.response.status === 401 && !originalRequest._retry) {
    //         if (isRefreshing) {
    //             return new Promise(function (resolve, reject) {
    //                 failedQueue.push({ resolve, reject })
    //             }).then(token => {
    //                 originalRequest.headers['Authorization'] = 'Bearer ' + token;
    //                 return api(originalRequest);
    //             }).catch(err => {
    //                 return Promise.reject(err);
    //             })
    //         }
    //         console.log("refreshing tokens")
    //         originalRequest._retry = true;
    //         isRefreshing = true;
    //         return new Promise(function (resolve, reject) {
    //             var body = {
    //                 "accessToken": sessionStorage.getItem('accessToken'),
    //                 "refreshToken": sessionStorage.getItem('refreshToken')
    //             }
    //             api.post('/tokens/refresh/', JSON.stringify(body))
    //             .then((data) => {
    //                 data = data.data
    //                     sessionStorage.setItem('access_token', data.access_token);
    //                     sessionStorage.setItem('refresh_token', data.refresh_token);
    //                     sessionStorage.setItem('expires_at',data.expires_at)
    //                     api.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken.token;
    //                     originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken.token;
    //                     processQueue(null, data.accessToken.token);
    //                     resolve(api(originalRequest));
    //                 })
    //                 .catch((err) => {
    //                     sessionStorage.clear()
    //                     //processQueue(err, null);
    //                     //reject(err);
    //                     document.location.reload()
    //                 })
    //                 .finally(() => { 
    //                     isRefreshing = false;
    //                 })
    //         })
    //     }
    //     return Promise.reject(error);
    // });
    
    // // Support token refresh during multirequest
    // let processQueue = (error, token = null) => {
    //     console.log('processQueue')
    //     failedQueue.forEach(prom => {
    //         if (error) {
    //             prom.reject(error);
    //         } else {
    //             prom.resolve(token);
    //         }
    //     })
    //     failedQueue = [];
    // }

    // ---------------------------------------------------------------------------------------
    //      PULS Controllers
    // ---------------------------------------------------------------------------------------

    this.GetPulsProfile = async() => {
        try{
            const response = await api.get(`/app/profile`)
            return(
                {
                    status: response.status,
                    data: response.data
                }
            )
        } catch(ex){
            console.error(ex)
        }
    }

    this.GetStations = async (favorits) => {
        try {
            var queries = favorits.map(obj => { return (api.get(`/bathingwater-stations/${obj.observationFacilityId}`))})
            const responses = await Promise.all(queries);
            return(responses.map((element, index) => { return element.data }));
        } catch (ex) {
            console.error(ex)
        }
    }

    this.GetStation = async (id) => {
        try {
            const response = await api.get(`/bathingwater-stations/${id}`)
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    this.GetExaminations = async(ids, from, to) => {
        try {
            const queries = ids.map((id)=>{return (api.get(`/bathingwater-stations/${id}/examinations?from=${from}&to=${to}`))})
            const responses = await Promise.all(queries);
            return(responses.map((element,index)=>{
                return {
                    id: ids[index],
                    data: element.data
                }
            }))
        } catch (ex) {
            console.error(ex)
            return;
        }
    }

    this.GetExamination = async(id, from, to) => {
        try {
            const response = await api.get(`/bathingwater-stations/${id}/examinations?from=${from}&to=${to}`);
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    this.ApproveExaminations = async(ids) => {
        try {
            const queries = ids.map((id)=>{
                return (api.post(`/examinations/approve`,{
                    "examinationId":id,
                    "remarks": "Godkendt i Mermaid."
                }))
            })
            const responses = await Promise.all(queries);

            return(responses.map((element,index)=>{
                return {
                    id: ids[index],
                    data: element.data
                }
            }))
        } catch (ex) {
            console.error(ex)
        }
    }

    this.RejectExamination = async(obj) => {
        try{
            const response = await api.post(`/examinations/reject`,obj)
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    this.GetReadings = async(id,from,to) => {
        try {
            const response = await api.get(`/bathingwater-stations/${id}/readings?from=${from}&to=${to}`)
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    this.GetEvents = async(id, year) => {
        try {
            const response = await api.get(`/bathingwater-stations/${id}/events/${year}`)
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    this.GetIncidents = async(ids,from,to) => {
        try {
            const queries = ids.map((id)=>{return (api.get(`/bathingwater-stations/${id}/incidents?from=${from}&to=${to}`))})
            const responses = await Promise.all(queries);
            return(responses.map((element,index)=>{
                return {
                    id: ids[index],
                    data: element.data
                }
            }))
        } catch (ex) {
            console.error(ex)
        }
    }

    this.GetAssessments = async(ids,years) => {
        try {
            let queries = []
            ids.forEach( async id => {
                years.map((year)=>{
                    queries.push(api.get(`/bathingwater-stations/${id}/assessments/${year}`))
                    return;
                })
            })

            const responses = await Promise.all(queries)

            return responses.map(response => {return response.data})

        } catch (ex) {
            console.error(ex)
        }
    }

    this.GetShortTermPollutions = async(id,years) => {
        try {
            const queries = years.map((year)=>{return (api.get(`/bathingwater-stations/${id}/short-term-pollutions?year=${year}`))})
            const responses = await Promise.all(queries)
            return(responses.map((element,index)=>{
                return({
                    id: years[index],
                    data: element.data
                })
            }))
        } catch (ex) {
            console.error(ex)
        }
    }

    this.RemoveShortTermPollution = async(id,txt) => {
        try{
            const response = await api.delete(`/bathingwater-short-term-pollutions/${id}`,{
                data: {
                    reason: txt
                }
            })
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    this.PostShortTermPollution = async(obj)=>{
        try{
            const response = await api.post(`/bathingwater-short-term-pollutions`,obj)
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }
    this.GetClassifications = async(id, limit) => {
        try {
            const response = await api.get(`/bathingwater-stations/${id}/classifications?limit=${limit}`)
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }
    
    this.GetSchedules = async(ids, years) => {
        try {
            var queries = [];
            ids.forEach(id => {
                years.forEach(year => {
                    queries.push(api.get(`/bathingwater-schedules/${id}/${year}`))
                });
            });

            const responses = await Promise.all(queries);

            return(responses.map(element => {
                return{
                    id: element.data.bathingwaterStationId,
                    data : element.data
                }
            }));

        } catch (ex) {
            console.error(ex)
        }
    }

    // ---------------------------------------------------------------------------------------
    //       API Controller
    // ---------------------------------------------------------------------------------------

    this.GetSettings = async() => {
        try {
            const response = await api.get(`/api/user/settings`)
            return({
                status: response.status,
                data: response.data
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    this.UpdateSettings = async(payload) => {
        try {
            const response = await api.post(`/api/user/settings`,payload)
            return{
                status: response.status,
                data: response.data
            }
        } catch (ex) {
            console.error(ex)
        }
    }
}