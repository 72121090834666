
import moment from 'moment'

// const moment = require('moment');

function dateToString(value,format="YYYY-MM-DD HH:mm:ss"){
    var dateString = moment(value).format(format);
    return dateString
}

function unixToString(value,format="YYYY-MM-DD HH:mm:ss"){
    var dateString = moment.unix(value).format(format);
    return dateString
}

function dateToPrint(value, format="DD-MM-YYYY HH:mm:ss"){
    return moment(value).format(format);
}

function stringToUnix(string){
    var unixDate = moment(string).unix()
    return unixDate
}

function stringToDate(string){
    var unixDate = moment(string)
    return unixDate
}

function dateToUnix(date){
    var unixDate = moment(date).unix()
    return unixDate
}

function unixToDate(value){
    var dateObj = new Date(moment.unix(value))
    return dateObj
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c ==='x' ? r : (r && 0x3 || 0x8);
        return v.toString(16);
    });
}

export {
    unixToString,
    stringToUnix,
    stringToDate,
    dateToString,
    dateToPrint,
    dateToUnix,
    unixToDate,
    uuidv4
}