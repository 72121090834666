import React, { useEffect, useReducer } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import {
    Container,
    Grid,
} from '@material-ui/core';

import { green, blue } from '@material-ui/core/colors';
import { Assessment, GetApp } from '@material-ui/icons';

import {
    ClassificationPanel,
    ExaminationPopup,
    Map,
    Menu,
    StationInfo,
    BathingwaterReport,
    SettingsDialog,
    ActionButton,
    LoadingOverlay,
} from '../../components';

import calculations from '../../utilities/calculations';
import restClient from '../../utilities/api';

import { reducer, ACTIONS } from './partials/reducer.js';

import mapStyles from './partials/mapstyle.json';

import {
    extractPULSDataToJSON,
    downloadZip,
    downloadCSV
} from '../../utilities/services'

var _ = require('lodash');

var url = process.env.REACT_APP_DATA_URL;
var apiUrl = process.env.REACT_APP_API_URL;

var pulsClient = new restClient(url);
var apiClient = new restClient(apiUrl);

const useStyles = makeStyles({
    GridElement: {
        backgroundColor: 'transparent',
        backgroundColor: '#999999'
    },
    Container: {
        backgroundColor: 'transparent',
        // backgroundColor: '#565656'
    },
    Header: {
        color: '#ffffff',
        fontWeight: 'bold'
    },
    App: {
        align: 'center'
    },
    SettingsMenu: {
        verticalAlign: 'right',
        position: 'relative'
    },
    Top: {
        backgroundColor: '#999999',
        padding: "0px 10px"
    },
});

export default function Overview({ user, stations }) {
    const classes = useStyles();

    const [state, dispatch] = useReducer(reducer, {
        ready: false,
        alert: {
            toggled: false,
            message: "",
            severity: "info"
        },
        loadingSubMessage: "",
        toggleStates: {
            analysis: false,
            settings: false
        },
        error: {
            hasError: false,
            message: ''
        },
        stationList: stations,
        userSettings: user,
        map: {
            settings: undefined,
            options: mapStyles,
            center: [],
        },
        settingsMenu: undefined,
        stationInFocus: undefined
    })

    const theme = createMuiTheme({
        palette: {
            primary: blue,
            secondary: green
        },
        status: {
            danger: 'orange'
        }
    })

    function handleClick(id) {
        let newStation = state.stationList.filter(ctx => ctx.observationFacilityId === id)
        if (newStation != null) {
            dispatch({ type: ACTIONS.SET_MAP_CENTER, payload: { station: newStation[0], zoom: 14 } })
            dispatch({ type: ACTIONS.SET_FOCUS_STATION, payload: newStation[0] })
        }
    };

    const ApproveBtnCallback = (ctx) => {
        console.log(ctx)
    }

    const RejectBtnCallback = (ctx) => {
        console.log(ctx)
    }

    const handleSettingsConfirmed = async (obj) => {
        const response = await apiClient.UpdateSettings(obj);
        if (response.status == 200) {
            dispatch({ type: ACTIONS.SET_MAP_STYLE, payload: obj.mapStyle })
            dispatch({ type: ACTIONS.SET_USER_SETTINGS, payload: obj })
        } else {
            // Error handling - Settings couldnt be saved to DB.
        }
    }

    const handleSettingsCancel = () => {
    }

    useEffect(async () => {

        try {

            // Set user map style
            dispatch({ type: ACTIONS.SET_MAP_STYLE, payload: state.userSettings.mapStyle });

            // Load stations
            let stations = state.stationList

            // remove not bathingwater stations
            let stationIds = stations.map(station => { return station.observationFacilityId });

            // Set dates for examination acquisition
            var d = new Date();
            var year = d.getFullYear() - 5;
            var startdate = new Date(year.toString().concat("-01", "-01")).toISOString();
            var enddate = d.toISOString();

            // set years for classification and examination plans
            let firstYear = d.getFullYear() - (state.userSettings.dataHistory-1);
            let years = Array(state.userSettings.dataHistory).fill().map((element,index)=>index + firstYear)


            // Get examination data
            dispatch({ type: ACTIONS.SET_LOAD_MESSAGE, payload: "Henter data." })
            let examinations = await pulsClient.GetExaminations(stationIds, startdate, enddate);

            if(!examinations)
                return;

            let test = _.cloneDeep(examinations)
            dispatch({ type: ACTIONS.SET_EXAMINATIONS, payload: test });


            // Minify data structure
            dispatch({ type: ACTIONS.SET_LOAD_MESSAGE, payload: "Minificer data." });
            examinations.forEach(station => {
                station.data = extractPULSDataToJSON(station.data);
            });

            // Get Incidents -> Skal laves om til short-term-pollutions
            dispatch({ type: ACTIONS.SET_LOAD_MESSAGE, payload: "Henter hændelser." });
            let incidents = await pulsClient.GetIncidents(stationIds, startdate, enddate);
            // let incidents = await pulsClient.GetShortTermPollutions()
            dispatch({ type: ACTIONS.SET_INCIDENTS, payload: incidents })
            
            // Load puls classifications
            dispatch({ type: ACTIONS.SET_LOAD_MESSAGE, payload: "Henter PULS klassifikation." });
            let assessments = await pulsClient.GetAssessments(stationIds,years)
            
            // Get Examination Plan
            dispatch({ type: ACTIONS.SET_LOAD_MESSAGE, payload: "Henter planer." });
            let schedules = await pulsClient.GetSchedules(stationIds, years);
            dispatch({ type: ACTIONS.SET_SCHEDULES, payload: schedules });

            // Attach data to station list
            stations = stations.map(station => {
                let stationExaminations = examinations.filter(exam => exam.id == station.observationFacilityId).map(x => { return x.data });
                let stationIncidents = incidents.filter(incident => incident.id == station.observationFacilityId).map(x => { return x.data });
                let stationSchedules = schedules.filter(schedule => schedule.id == station.observationFacilityId).map(x => { return x.data });
                let stationAssessments = assessments.filter(assessment => assessment.bathingwaterStationId == station.observationFacilityId);

                station.data = stationExaminations[0];
                station.schedules = stationSchedules;
                station.incidents = stationIncidents[0];
                station.assessments = stationAssessments;
                return station
            })

            // do Calculations
            var newData = calculations.status(stations, state.userSettings);
            newData = calculations.markData(newData);
            // newData = calculations.classification(newData);
            newData = calculations.pulsClassification(newData);
            dispatch({ type: ACTIONS.SET_STATION_LIST, payload: newData });

            // Save geoJson
            dispatch({ type: ACTIONS.SET_STATION_LIST, payload: stations });

            // Set first station on list as focus
            dispatch({ type: ACTIONS.SET_FOCUS_STATION, payload: stations[0] })
            dispatch({ type: ACTIONS.SET_MAP_CENTER, payload: { station: stations[0], zoom: 11 } })

            // Set ready
            dispatch({ type: ACTIONS.SET_READY })

        } catch (error) {
            console.error(error)
        }
    }, []);


    // calculate status
    useEffect(() => {
        if (state.ready) {
            var newData = calculations.status(state.stationList, state.userSettings);
            newData = calculations.markData(newData);
            // newData = calculations.classification(newData);
            newData = calculations.pulsClassification(newData);
            // add new calculation service called pulsClassification and map assessments to stationList.classifications
            dispatch({ type: ACTIONS.SET_STATION_LIST, payload: newData });
        }
    }, [state.userSettings])


    if (state.error.hasError) {
        return (
            <Container className={classes.Container} maxWidth='lg'>
                <LoadingOverlay text={state.error.message} isError={state.error.hasError} />
            </Container>
        );
    }

    if (!state.ready) {
        return (
            <Container className={classes.Container} maxWidth='lg'>
                <LoadingOverlay text={state.loadingSubMessage} />
            </Container>

        );
    } else {
        return (
            <ThemeProvider theme={theme}>
                <Container className={classes.Container} maxWidth='lg'>
                    <Grid className={classes.Grid} container spacing={2}>
                        <Grid container className={classes.Top} direction='row' justify="space-between">
                            <Grid item>
                                {/* <div><img alt="" src="custom/logo.png" /></div> */}
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justify="flex-end" spacing={3}>
                                    <Grid item>
                                        <ActionButton
                                            tooltip="Download seneste 3 års data for alle stationer."
                                            icon={<Assessment />}
                                            callback={() => downloadZip(state.stationList)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ActionButton
                                            tooltip="Download datagrundlag for seneste 3 års klassifikationer."
                                            icon={<GetApp />}
                                            callback={() => downloadCSV(state.stationInFocus)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BathingwaterReport station={state.stationInFocus} />
                                    </Grid>
                                    <Grid item>
                                        <SettingsDialog
                                            userOptions={state.userSettings}
                                            mapOptions={state.map.options}
                                            onConfirm={(obj) => { handleSettingsConfirmed(obj) }}
                                            onCancel={() => { handleSettingsCancel() }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={classes.GridElement} item xs={4} >
                            <Menu
                                items={state.stationList}
                                onClick={(id) => handleClick(id)}
                                onDblClick={() => { dispatch({ type: ACTIONS.TOGGLE, payload: 'analysis' }) }}
                            />
                        </Grid>
                        <Grid className={classes.GridElement} item xs={8}>
                            <Map
                                stations={state.stationList}
                                callback={(id) => handleClick(id)}
                                options={{
                                    disableDefaultUI: true,
                                    zoomControl: true,
                                    zoom: state.map.center[2],
                                    center: {
                                        lng: state.map.center[0],
                                        lat: state.map.center[1]
                                    },
                                    styles: state.map.settings
                                }}
                            />
                        </Grid>
                        <Grid className={classes.GridElement} item xs={7}>
                            <StationInfo station={state.stationInFocus} />
                        </Grid>
                        <Grid className={classes.GridElement} item xs={5}>
                            <ClassificationPanel station={state.stationInFocus} />
                        </Grid>
                    </Grid>
                    <ExaminationPopup
                        show={state.toggleStates.analysis}
                        title={state.stationInFocus.name}
                        analysis={state.stationInFocus.data}
                        handleClose={() => { dispatch({ type: ACTIONS.TOGGLE, payload: 'analysis' }) }}
                        handleApproveBtn={(ctx) => { ApproveBtnCallback(ctx) }}
                        handleRejectBtn={(ctx) => { RejectBtnCallback(ctx) }}
                    />
                </Container>
            </ThemeProvider>
        );
    };
};