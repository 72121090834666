import React from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core/styles';

function customCheckbox(theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
        },
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
        color:
            theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.type === 'light'
                    ? 'rgba(0,0,0,.85)'
                    : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        ...customCheckbox(theme),
    },
}));

export default function ExaminationGrid({ items, callback }) {
    const classes = useStyles();
    
    var colDef = [
        "displayDate",
        "Approval",
        "sampleDesc",
        "Escherichia.coli",
        "Intestinal enterokokker",
        "Vandtemperatur",
        "pH",
        "Sigtdybde",
        "Totaldybde",
        "Antal badende",
        "Nedbør",
        "Skydække",
        "Strandkvalitet",
        "Strømretning",
        "Vindretning",
        "Vindstyrke"
    ]
    var colHeaders = [
        "Dato",
        "Godkendelse",
        "Type",
        "E.Coli",
        "Intestinal enterokokker",
        "Vandtemperatur",
        "pH",
        "Sigtdybde",
        "Totaldybde",
        "Antal badende",
        "Nedbør",
        "Skydække",
        "Strandkvalitet",
        "Strømretning",
        "Vindretning",
        "Vindstyrke"
    ]

    var dataColumns = colHeaders.map((col, i) => {
        return (
            {
                field: colDef[i],
                headerName: col,
                flex:1
                // width: col.length * 12
            }
        )
    })

    var dataRows = items.map((item, i) => {

        var returnObj = { id: i }

        for (let index = 0; index < colDef.length; index++) {
            returnObj[colDef[index]] = item[colDef[index]]
        }
        return (returnObj)
    })

    const selectionMade = (selectionIndex) => {

        var d = [];
        selectionIndex.map((s,i) => {
            d[i] = items[s];
        })

        callback(d.map(x => {return x.examinationId}))
        // console.log(d.map(x => {return x.examinationId}))
    }

    return (
        <div style={{ height: "80vh", width: '95vw' }}>
            <DataGrid
                className={classes.root}
                checkboxSelection
                pageSize={15}
                onSelectionModelChange={(s)=>{selectionMade(s.selectionModel)}}
                // components={{
                //     Pagination: CustomPagination,
                // }}
                rows={dataRows}
                columns={dataColumns}
            />
        </div>
    )
}


