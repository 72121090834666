const ACTIONS = {
    SET_STATE:'set-state',
    TOGGLE:'toggle',
    SET_ERROR:'set-error',
    SET_LOAD_MESSAGE:'set-load-message',
    SET_USER_SETTINGS:'set-user-settings',
    SET_MAP_STYLE:'set-map-style',
    SET_MAP_OPTIONS:'set-map-options',
    SET_MAP_CENTER:'set-map-center',
    SET_STATION_LIST:'set-station-list',
    SET_FOCUS_STATION: 'set-focus-station',
    SET_EXAMINATIONS:'set-examinations',
    SET_INCIDENTS:'set-incidents',
    SET_SCHEDULES: 'set-schedules',
    SET_READY: 'set-ready',
    TOGGLE_ALERT: 'toggle-alert'
}

const reducer = (state,action) => {
    switch(action.type){

        case ACTIONS.SET_STATE:
            return {
                ...state,
                [action.payload.handle]: action.payload.state
            }

        case ACTIONS.SET_READY:
            return {
                ...state,
                ready: true
            }

        case ACTIONS.TOGGLE:
            return{
                ...state,
                toggleStates:{
                    [action.payload]: !state.toggleStates[action.payload]
                }
            }

        case ACTIONS.SET_LOAD_MESSAGE:
            return{
                ...state,
                loadingSubMessage: action.payload
            }

        case ACTIONS.SET_STATION_LIST:
            return{
                ...state,
                stationList: action.payload
            }
        case ACTIONS.SET_FOCUS_STATION:
            return {
                ...state,
                stationInFocus: action.payload
            }
        
        case ACTIONS.SET_MAP_CENTER:
            return{
                ...state,
                map: {
                    ...state.map,
                    center: [
                        action.payload.station.position.longitude,
                        action.payload.station.position.latitude,
                        action.payload.zoom
                    ]
                }
            }

        case ACTIONS.SET_MAP_STYLE:
            var userMapStyle = state.map.options.find(x => x.name == action.payload)?.style;
            return{
                ...state,
                map:{
                    ...state.map,
                    settings: userMapStyle
                }
            }

        case ACTIONS.SET_MAP_OPTIONS:
            return{
                ...state,
                map:{
                    ...state.map,
                    options: action.payload
                }
            }

        case ACTIONS.SET_EXAMINATIONS:
            return{
                ...state,
                examinations: action.payload
            }

        case ACTIONS.SET_INCIDENTS:
            return{
                ...state,
                incidents: action.payload
            }
        case ACTIONS.SET_SCHEDULES:
            return{
                ...state,
                schedules: action.payload
            }
        case ACTIONS.SET_ERROR:
            return{
                ...state,
                error: {
                    ...state.error,
                    hasError:action.payload.hasError,
                    message: action.payload.message
                }
            }
        case ACTIONS.SET_USER_SETTINGS:
            return{
                ...state,
                userSettings: action.payload
            }

        case ACTIONS.TOGGLE_ALERT:
            return {
                ...state,
                alert: {
                    ...action.payload
                }
            }

        default:
            return state
    }
}

export {reducer,ACTIONS}