import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    Accordion,
    AccordionSummary,
    AccordionActions,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    Typography,
    Button,
    Card,
    CardContent,
    Grid,
} from '@material-ui/core'


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    noData:{
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16,
        paddingTop: 15,
        height: 48,
    }
});


export default function PollutionContext({pollutions,callback}) {
    const classes = useStyles();


    var pollutionContext = pollutions?.map((obj,i)=>{
        return(
            <Accordion key={`accordian-key-${i}`}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                   <Typography>{obj?.sample.samplingTime}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container className={classes.root} direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item key={`grid-sample-row-${i}`}>
                            <Card className={classes.card} key={`card-${i}`}>
                                <CardContent>
                                    <Typography key={`card-header-${i}`} variant="h5">Ordinær prøve</Typography>
                                    <Typography key={`card-date-${i}`} variant="body2" component="p">{obj["sample"].samplingTime}</Typography>
                                    <Typography key={`card-ecoli-${i}`} variant="body2" component="p">E. Coli: {obj["sample"].escherichiaColi}</Typography>
                                    <Typography key={`card-entero-${i}`} variant="body2" component="p">Int. Enterokokker: {obj["sample"].intestinalEnterococci}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key={`grid-confirmationSample-row-${i}`}>
                            <Card className={classes.card} key={`card-${i}`}>
                                <CardContent>
                                    <Typography key={`card-header-${i}`} variant="h5">Omprøve</Typography>
                                    <Typography key={`card-date-${i}`} variant="body2" component="p">{obj["confirmationSample"].samplingTime}</Typography>
                                    <Typography key={`card-ecoli-${i}`} variant="body2" component="p">E. Coli: {obj["confirmationSample"].escherichiaColi}</Typography>
                                    <Typography key={`card-entero-${i}`} variant="body2" component="p">Int. Enterokokker: {obj["confirmationSample"].intestinalEnterococci}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key={`grid-replacementSample-row-${i}`}>
                            <Card className={classes.card} key={`card-${i}`}>
                                <CardContent>
                                    <Typography key={`card-header-${i}`} variant="h5">Erstatningsprøve</Typography>
                                    <Typography key={`card-date-${i}`} variant="body2" component="p">{obj["replacementSample"].samplingTime}</Typography>
                                    <Typography key={`card-ecoli-${i}`} variant="body2" component="p">E. Coli: {obj["replacementSample"].escherichiaColi}</Typography>
                                    <Typography key={`card-entero-${i}`} variant="body2" component="p">Int. Enterokokker: {obj["replacementSample"].intestinalEnterococci}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <AccordionActions>
                <Button variant="contained" size="small" color="secondary" onClick={()=>callback(obj.id)}>
                    Slet
                </Button>
                </AccordionActions>
            </Accordion>
        )
    })

    return (
        <div className={classes.root}>
            {pollutionContext?.length>0 ? pollutionContext :
                <div className={classes.noData}>Ingen data.</div>
            }
        </div>
    )
}