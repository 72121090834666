import React, { useState, useEffect } from 'react'
import { Marker } from '@react-google-maps/api';

import error from './img/error.png';
import nodata from './img/nodata.png';
import ok from './img/ok.png';
import warning from './img/warning.png';


var icons = {
    "error": error,
    "nodata": nodata,
    "ok": ok,
    "warning": warning
}

export default function CustomMarker({ data, onClickEffect, onMouseOverEffect, icon, position }) {
    return (
        <Marker
            icon={`${icons[icon]}`}
            onClick={() => onClickEffect()}
            onMouseOver={() => onMouseOverEffect()}
            position={position}
        />
    )
}