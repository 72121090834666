import moment from 'moment'

const extractPULSDataToCSV = (pulsObj) => {

    const separator = ";"
    var json = []

    pulsObj.map(element => {
        var out = {}
        out["Dato"] = element["csvDate"]
        out["Inkluderet"] = element["includeClassification"] ? "Ja" : "Nej"
        out["Type"] = element["sampleDesc"]

        Object.keys(element).forEach(key => {
            out[key] = element[key]
        })

        delete out["classificationPeriod"]
        delete out["year"]
        delete out["displayDate"]
        delete out["csvDate"]
        delete out["date"]
        delete out["sampleType"]
        delete out["observationFacilityId"]
        delete out["examinationId"]
        delete out["includeClassification"]

        json.push(out)
    })

    json.reverse()

    const fixedHeaders = [
        "Dato",
        "Inkluderet",
        "Approval",
        "Type",
        "Escherichia coli",
        "Intestinal enterokokker",
        "pH",
        "Vandtemperatur",
        "Sigtdybde",
        "Totaldybde",
        "Antal badende",
        "Nedbør",
        "Skydække",
        "Vindstyrke",
        "Strømretning",
        "Strandkvalitet",
        "Vandkvalitet",
        "Vindretning",
    ]

    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = fixedHeaders //Object.keys(json[0])
    const csvObj = [
        header.join(separator), // header row first
        ...json.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(separator))
    ].join('\r\n')

    return csvObj
}

const extractPULSDataToJSON = (pulsDataArray) => {
    var json = []

    if (!pulsDataArray)
        return json;

    pulsDataArray.map((exam) => {
        var returnObj = {}

        var d = new Date(exam.sampling.samplingStarted)

        returnObj.examinationId = exam.examinationId;
        returnObj.observationFacilityId = exam.observationFacilityId;

        returnObj.sampleType = exam.purpose.value;
        returnObj.sampleDesc = exam.purpose.description;

        switch (exam.status) {
            case "Approved":
                returnObj.Approval = "Godkendt"
                break;
            case "Pending":
                returnObj.Approval = "Afventer"
                break;
            case "Rejected":
                returnObj.Approval = "Afvist"
                break;
            default:
                returnObj.Approval = "Ukendt"
        }

        returnObj.date = d.toISOString();

        // returnObj.displayDate = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes() + d.getSeconds();
        // returnObj.displayDate = d.toLocaleDateString('da-DK',{
        //     year: 'numeric',
        //     month: '2-digit',
        //     day:'2-digit',
        //     hour:'2-digit',
        //     minute:'2-digit'
        // });
        returnObj.displayDate = moment(d).format('YYYY-MM-DD HH:mm:ss')
        returnObj.csvDate = moment(d).format('DD-MM-YYYY HH:mm:ss')

        // samplings.analyses[]
        // Ecoli, Enterococcus
        exam.sampling.sample.analyses.map((analyse) => {
            returnObj[analyse.parameter.description] = analyse.value ? analyse.value : null
            if (analyse.attribute) {
                returnObj[`${analyse.parameter.description}-attr`] = analyse.attribute.description
            }
        })

        // measurements[]
        // Temperatur, Sigtdybde, pH
        exam.measurements.map((meas) => {
            returnObj[meas.parameter.description] = meas.value ? meas.value : null
        })

        // observations[]
        // Vindstyrke, Antal badende, Strømretning, Nedbør, Strandkvalitet, Skydække, Vindretning, Vandkvalitet
        exam.observations.map((obs) => {
            returnObj[obs.parameter.description] = obs.value.description
        })

        json.push(returnObj)

    })

    return json.reverse()
}

const convertToGeoJson = (stationsArray) => {
    var geoJson = {}

    geoJson.type = "FeatureCollection"
    geoJson.features = []


    stationsArray.forEach(station => {
        var feat = {
            type: "Feature",
            properties: {
                id: station.observationFacilityId,
                name: station.name,
                cvr: station.responsible.cvr,
                facilityType: station.observationFacilityType,
                inspectionStation: true,
                responsible: station.responsible.name,
                waterType: station.waterType,
                waterAreaName: station.waterArea.name,
                waterAreaNumber: station.waterArea.name,
                blueFlag: station.blueFlag,
                dkbw: station.dkbw,
                mergedStation: station.mergedStation,
                started: station.started,
                closed: station.closed,
                remarks: station.remarks,
                data: station.data,
                schedules: station.schedules,
                incidents: station.incidents,
                icon: "",
                classifications: 0,
                position: [station.position.longitude, station.position.latitude]
            },
            geometry: {
                type: "Point",
                coordinates: [station.position.longitude, station.position.latitude]
            }
        }
        geoJson.features.push(feat);
    });

    return geoJson;
}

const downloadZip = (data) => {
    var outObj = [];
    data.map(element => {
        var csvData = extractPULSDataToCSV(element.data)
        outObj.push({
            id: element.name,
            csv: csvData
        })
    })

    packCSVfiles(outObj, (zip) => {
        saveBlobToFile(zip, 'data.zip')
    })
}

const downloadCSV = (station) => {
    var csv = extractPULSDataToCSV(station.data)

    var strDate = new Date(Date.now()).toISOString()
    strDate = strDate.split(".")[0];
    strDate = strDate.replace("T", " ");
    strDate = strDate.replace(/:/g, "-");
    var saveName = station.name + " " + strDate

    let blob = new Blob([csv], { type: "application/text;charset=utf-8" });

    saveBlobToFile(blob, saveName + ".csv")
}


const saveBlobToFile = (object, fileName) => {
    const url = window.URL.createObjectURL(object);
    createDownloadURL(url, fileName);
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 1000);
};

const createDownloadURL = (data, fileName) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
};

const packCSVfiles = (arr = [], callback) => {
    var JSzip = require('jszip');
    var zip = new JSzip();

    arr.map((obj) => {
        zip.file(`${obj.id}.csv`, obj.csv, { binary: true })
    })

    zip.generateAsync({ type: 'blob' })
        .then((content) => {
            callback(content)
        })
}

export {
    extractPULSDataToCSV,
    extractPULSDataToJSON,
    convertToGeoJson,
    downloadZip,
    downloadCSV
}
