const ACTIONS = {
    SET_STATE: 'set-state',
    SET_READY: 'set-ready',
    SET_DATA_LOADING: 'set-data-loading',
    SET_POLLUTION_LIST:'set-pollution-list',
    SET_SELECTED_STATION:'set-selected-station',
    SET_SELECTED_YEAR:'set-selected-year',
    SET_SELECTED_POLLUTION: 'set-selected-pollution',
    SET_FORM_DATA: 'set-form-data',
    TOGGLE_CREATE_FORM: 'toggle-create-form',
    SET_CONFIRM_TEXT:'set-confirm-text',
    SET_REMOVAL_REASON:'set-removal-reason',
    SET_REMOVAL_ID:'set-removal-id',
    TOGGLE_CONFIRM_BOX: 'toggle-confirm-box',
    SHOW_ERROR: 'set-error',
    HIDE_ERROR: 'hide-error',

}

const reducer = (state, action) => {
    switch(action.type){
        case ACTIONS.SET_STATE:
            return {
                ...state,
                [action.payload.name]: action.payload.state
            }

        case ACTIONS.SET_READY:
            return {
                ...state,
                ready:true
            }
        case ACTIONS.SET_DATA_LOADING:
            return {
                ...state,
                dataLoading: !state.dataLoading
            }
        case ACTIONS.SET_POLLUTION_LIST:
            return {
                ...state,
                pollutionList: action.payload
            }
        case ACTIONS.SET_SELECTED_STATION:
            return {
                ...state,
                selected:{
                    ...state.selected,
                    station: action.payload
                }
            }
        case ACTIONS.SET_SELECTED_YEAR:
            return {
                ...state,
                selected:{
                    ...state.selected,
                    year: action.payload
                }
            }
        
        case ACTIONS.SET_SELECTED_POLLUTION:
            return {
                ...state,
                selected:{
                    ...state.selected,
                    pollutions: action.payload
                }
            }
        case ACTIONS.SET_FORM_DATA:
            return {
                ...state,
                createForm:{
                    ...state.createForm,
                    data: action.payload
                }
            }
        case ACTIONS.TOGGLE_CREATE_FORM:
            return {
                ...state,
                createForm:{
                    ...state.createForm,
                    show: !state.createForm.show
                }
            }
        case ACTIONS.SET_CONFIRM_TEXT:
            return {
                ...state,
                confirmRemoval:{
                    ...state.confirmRemoval,
                    text: action.payload
                }
            }
        case ACTIONS.SET_REMOVAL_ID:
            return {
                ...state,
                confirmRemoval:{
                    ...state.confirmRemoval,
                    id: action.payload
                }
            }
        case ACTIONS.SET_REMOVAL_REASON:
            return {
                ...state,
                confirmRemoval:{
                    ...state.confirmRemoval,
                    reason: action.payload
                }
            }
        case ACTIONS.TOGGLE_CONFIRM_BOX:
            return {
                ...state,
                confirmRemoval:{
                    ...state.confirmRemoval,
                    reason:'',
                    open: !state.confirmRemoval.open
                }
            }
        case ACTIONS.SHOW_ERROR:
            return {
                ...state,
                error: {
                    text: action.payload.text,
                    severity: action.payload.severity,
                    open: true
                }
            }
        case ACTIONS.HIDE_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    open: false
                }
            }

        default:
            return state
    }
}


export {reducer,ACTIONS}