const ACTIONS = {
    SET_DEFAULT:'set-default',
    SET_DATA: 'set-data',
    SET_STEP: 'set-step',
    SET_SELECTED: 'set-selected',
    REMOVE_SELECTED: 'remove-selected',
    SET_FORM: 'set-form',
    RESET_FORM:'reset-form',
    SET_CONFIRM_CONTENT:'set-confirm-content',
    SHOW_ERROR: 'set-error',
    HIDE_ERROR: 'hide-error',
    TOGGLE_CONFIRM: 'toggle-confirm',
}

const reducer = (state, action) => {
    switch(action.type){
        case ACTIONS.SET_DEFAULT:
            return{
                ...state,
                form: action.payload
            }
        case ACTIONS.SET_DATA:
            return {
                ...state,
                data: action.payload
            }
        case ACTIONS.SET_STEP:
            return {
                ...state,
                activeStep: action.payload
            }
        case ACTIONS.SET_SELECTED:
            return {
                ...state,
                selected: action.payload
            }
        case ACTIONS.REMOVE_SELECTED:
            return {
                ...state,
                selected: [...state.selected.filter(obj=>{return obj.examinationId !== action.payload})]
            }
        case ACTIONS.SET_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.type]: action.payload.id
                }
            }
        case ACTIONS.RESET_FORM:
            return {
                ...state,
                form: action.payload,
                selected: [],
                confirmContent: [],
                confirmBox:{
                    ...state.confirmBox,
                    open: false
                }

            }
        case ACTIONS.SET_CONFIRM_CONTENT:
            return {
                ...state,
                confirmContent: action.payload
            }
        case ACTIONS.SHOW_ERROR:
            return {
                ...state,
                error: {
                    text: action.payload.text,
                    severity: action.payload.severity,
                    open: true
                }
            }
        case ACTIONS.HIDE_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    open: false
                }
            }
        case ACTIONS.TOGGLE_CONFIRM:
            return {
                ...state,
                confirmBox:{
                    open: !state.confirmBox.open,
                    text: action.payload
                }
            }

        default:
            return state
    }
}
export {reducer,ACTIONS}