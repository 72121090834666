import React, { useState, useEffect } from 'react'

import {
    Card,
    CardContent,
    Grid,
    Typography,
    Radio,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '50vh'
    },
    card: {
        minWidth: 275,
    }
}));


export default function PollutionTypeSelector({ items, form, type, callback }) {
    const [internalForm, setInternalForm] = useState([]);

    const classes = useStyles();

    const handleSelect = (type, id) => {
        callback(type, id);
    }

    useEffect(() => {
        setInternalForm(form);
    }, [form])

    return (
        <Grid container className={classes.root} direction="row" justify="center" alignItems="center" spacing={5}>
            {
                items.map((obj, i) => {
                    return (
                        <Grid item key={`grid-obj-row-${i}`}>
                            <Card className={classes.card} key={`card-${i}`}>
                                <CardContent>
                                    <Typography key={`card-header-${i}`} variant="h5">{obj.displayDate}</Typography>
                                    <Typography key={`card-ecoli-${i}`} variant="body2" component="p">E. Coli: {obj['E.coli']}</Typography>
                                    <Typography key={`card-entero-${i}`} variant="body2" component="p">Int. Enterokokker: {obj['Intestinal enterokokker']}</Typography>
                                </CardContent>
                                <Radio 
                                    checked={internalForm[type] === obj.examinationId}
                                    onChange={()=>handleSelect(type,obj.examinationId)}
                                    value={obj.examinationId}
                                    disabled={obj.type === type}
                                    name=""
                                    inputProps={{'arial-label':`a-label-${i}`}} 
                                />
                            </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}
